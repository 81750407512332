import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportsService} from '../../../../_services/reports.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {ApiService} from '../../../../_interceptors/api.service';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {ReportsIkeaPalletOption, ReportsIkeaPalletReport} from '../../../../_interfaces/reports/reports-ikea-pallet-report';
import {ServerResponse} from '../../../../_interfaces/server.response';

@Component({
	selector: 'app-reports-pallet-reports-edit',
	templateUrl: './reports-pallet-reports-edit.component.html',
	styleUrls: ['./reports-pallet-reports-edit.component.scss'],
	animations: [routeAnimation]
})

export class ReportsPalletReportsEditComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public pallet_report_id: number = null;

	public palletOptions: ReportsIkeaPalletOption[] = [];
	public hallways: Array<number> = [];

	public palletReport: ReportsIkeaPalletReport = null;

	public palletReportForm = this.formBuilder.group({
		hallways: [''],
		position_nr: ['', Validators.required],
		field: ['', Validators.required],
		level: ['', Validators.required],
		pallet_option_ids: [''],
		notes: ['']
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				public formsService: FormsService,
				private formBuilder: FormBuilder,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.formsService.form = this.palletReportForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this.pallet_report_id = parseInt(params['pallet_report_id'], 10);

			this.reportsService.getPalletReport(this.report_id, this.pallet_report_id).then((data: ReportsIkeaPalletReport) => {
				if (typeof data !== 'undefined') {
					this.formsService.populateForm(data);
					this.palletReport = data;

					const form = this.palletReportForm;
					form.removeControl('hallways');
					form.addControl('hallways', new FormArray([]));
					const hallways = <FormArray>this.palletReportForm.get('hallways');
					for (let i = 1; i < 100; i++) {
						this.hallways.push(i);
						hallways.push(new FormControl(i));

						const thisControl = this.palletReportForm.get('hallways').get((i - 1) + '');
						if (typeof data.hallways !== 'undefined') {
							if (data.hallways !== null) {
								if (data.hallways.indexOf(thisControl.value) === -1) {
									thisControl.setValue(false);
								}
							} else {
								thisControl.setValue(false);
							}
						} else {
							thisControl.setValue(false);
						}
					}

					this.reportsService.getPalletOptions().then((options: ServerResponse) => {
						if (typeof options.data !== 'undefined') {
							this.palletOptions = <ReportsIkeaPalletOption[]>options.data;
							form.removeControl('pallet_option_ids');
							form.addControl('pallet_option_ids', new FormArray([]));

							const control = <FormArray>this.palletReportForm.get('pallet_option_ids');
							options.data.forEach((data2, index) => {
								control.push(new FormControl(data2.id));

								const thisControl = this.palletReportForm.get('pallet_option_ids').get(index + '');
								if (typeof data.pallet_option_ids !== 'undefined') {
									if (data.pallet_option_ids !== null) {
										if (data.pallet_option_ids.indexOf(thisControl.value) === -1) {
											thisControl.setValue(false);
										}
									} else {
										thisControl.setValue(false);
									}
								} else {
									thisControl.setValue(false);
								}
							});
						}
					});
				}
			});
		});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		})
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	fixAngularScrewUpAndInsertActualValue(control_name, event, index) {
		if (event.checked) {
			this.palletReportForm.controls[control_name]['controls'][index].setValue(parseInt(event.source.value, 10));
		}
	}

	checkAll() {
		const hallways = <FormArray>this.palletReportForm.get('hallways');
		hallways.controls.forEach((hallway, index) => {
			hallway.setValue(index + 1);
		});
	}

	uncheckAll() {
		const hallways = <FormArray>this.palletReportForm.get('hallways');
		hallways.controls.forEach((hallway, index) => {
			hallway.setValue(false);
		});
	}
}
