import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Router} from '@angular/router';
import {routeAnimation} from '../../../route.animation';
import {AuthenticationService} from '../../../_services/authentication.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	animations: [routeAnimation]
})

export class LoginComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public returnUrl: string = '/';

	public loginForm = this.formBuilder.group({
		email: ['', [Validators.required, Validators.email]],
		password: ['', Validators.required]
	});

	private _userAuthSubscription: Subscription = null;
	private _formErrorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(private activatedRoute: ActivatedRoute,
				private router: Router,
				public authenticationService: AuthenticationService,
				private pageTitleService: PageTitleService,
				private formBuilder: FormBuilder,
				public formsService: FormsService,
				public translate: TranslateService) {
	}

	ngOnInit(): void {
		this.translate.get('auth.login.page-title').subscribe(text => this.pageTitleService.setTitle(text));

		this.formsService.form = this.loginForm;

		this._userAuthSubscription = this.authenticationService.user.first().subscribe(user => {
			if (user) {
				this.router.navigate(['/']).then(() => {});
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.queryParams.subscribe((params: Params) => {
			this.returnUrl = params['returnUrl'] || '/';
		});

		this._formErrorsSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._userAuthSubscription !== null) {
			this._userAuthSubscription.unsubscribe();
		}
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
		if (this._formErrorsSubscription !== null) {
			this._formErrorsSubscription.unsubscribe();
		}
	}
}
