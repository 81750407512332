import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {GeneralService} from '../../../_services/general.service';

import {LocalStorageService} from '../../../_services/local-storage.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {User} from '../../../_interfaces/user';

@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss'],
	animations: [routeAnimation]
})

export class MyAccountComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	myProfileForm = this.formBuilder.group({
		firstname: ['', [Validators.required, Validators.maxLength(50)]],
		lastname: ['', [Validators.required, Validators.maxLength(50)]],
	});

	_errorsSubscription: any = null;

	constructor(public authenticationService: AuthenticationService,
				private generalService: GeneralService,
				private localStorageService: LocalStorageService,
				public formsService: FormsService,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.formsService.form = this.myProfileForm;

		let currentUser: User = this.localStorageService.get('currentUser');
		this.formsService.populateForm(currentUser);

		this._errorsSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
