import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Injectable()

export class PageTitleService {

	public title: string = 'KVVM';

	constructor(private titleService: Title,
				private translate: TranslateService) {
	}

	setTitle(title: string = 'KVVM') {
		this.translate.get('general.page-title').subscribe(text => {
			this.titleService.setTitle(title + ' | ' + text);
			// this.title = title.replace(' | ' + environment.webappName, '');
			this.title = title;
		});
	}

	setPageTitle(title: string = 'KVVM') {
		// this.title = title.replace(' | ' + environment.webappName, '');
		this.title = title;
	}
}
