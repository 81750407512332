import {AfterViewInit, Component, ElementRef, HostBinding, Inject, ViewChild} from '@angular/core';
import {routeAnimation} from '../../route.animation';
import {MAT_DIALOG_DATA, MatDialogRef, MatTooltip} from '@angular/material';
import {ReportsBuildingsMapsMarker} from '../../_interfaces/reports/reports-buildings-maps-marker';

@Component({
	selector: 'app-building-map-widget',
	templateUrl: './building-map-widget.component.html',
	styleUrls: ['./building-map-widget.component.scss'],
	animations: [routeAnimation]
})

export class BuildingMapWidgetComponent implements AfterViewInit {
	@HostBinding('@routeAnimation') routerTransition = true;

	@ViewChild('mapZoom', {static: false}) mapZoom: ElementRef;
	@ViewChild('mapImage', {static: false}) mapImage: ElementRef;
	@ViewChild('tooltip', {static: false}) tooltip: MatTooltip;

	public tooltipMessage: string = null;

	public buildingMapUrl: string = null;
	private initialMarkers: ReportsBuildingsMapsMarker[] = [];
	public markers: ReportsBuildingsMapsMarker[] = [];

	public showHelp: boolean = false;

	private scale: number = 1;
	private panning: boolean = false;
	private pointX: number = 0;
	private pointY: number = 0;
	private start: { x: number, y: number } = {x: 0, y: 0};
	private clickStart: number = null;
	public readonly: boolean = true;
	private readonlyOriginal: boolean = true;

	private loadImgWidth: number = 0;
	private loadImgHeight: number = 0;

	constructor(public dialogRef: MatDialogRef<BuildingMapWidgetComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.buildingMapUrl = data.buildingMapUrl;

		if (typeof data.readonly !== 'undefined') {
			this.readonly = data.readonly;
			this.readonlyOriginal = data.readonly;
		}

		if (typeof data.markers !== 'undefined') {
			this.initialMarkers = data.markers;
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.initialMarkers.length) {
				let promises = [];

				this.readonly = false;
				this.waitForMapImageToLoad().then(() => {
					this.initialMarkers.forEach(marker => {
						promises.push(this.addMarker(marker).then(() => true));
					});

					Promise.all(promises).then(() => this.readonly = this.readonlyOriginal);
				});
			}
		}, 300);
	}

	toggleHelp(): void {
		this.showHelp = !this.showHelp;
	}

	zoomOut(): void {
		console.log('zoom out');
		let wheelEvent = new WheelEvent('wheel', <any>{
			deltaY: 1,
			deltaMode: WheelEvent.DOM_DELTA_LINE,
			target: this.mapZoom.nativeElement
		});

		this.onMouoseWheelScroll(wheelEvent);
	}

	zoomFit(): void {
		this.scale = 1;
		this.pointX = 0;
		this.pointY = 0;
		this.start = {x: 0, y: 0};
		this.panning = false;
		this.setTransform();
	}

	zoomIn(): void {
		console.log('zoom in');
		let wheelEvent = new WheelEvent('wheel', {
			deltaY: -1,
			deltaMode: WheelEvent.DOM_DELTA_LINE,
			relatedTarget: this.mapZoom.nativeElement
		});

		this.onMouoseWheelScroll(wheelEvent);
	}

	undo(): void {
		this.markers.slice(0).forEach(marker => {
			this.deleteMarker(marker.id);
		});
	}

	close(): void {
		this.dialogRef.close();
	}

	setTransform(): void {
		this.mapZoom.nativeElement.style.transform = 'translate(' + this.pointX + 'px, ' + this.pointY + 'px) scale(' + this.scale + ')';
	}

	addMarker(e: MouseEvent | { location_nr?: string, x: number, y: number }): Promise<any> {
		return new Promise(resolve => {
			if (this.readonly) {
				return false;
			}
			let bounds = this.mapImage.nativeElement.getBoundingClientRect(),
				p = (this.mapImage.nativeElement.width / this.mapImage.nativeElement.naturalWidth),
				x = (typeof (<MouseEvent>e).clientX !== 'undefined' ? (((<MouseEvent>e).clientX - bounds.left) / this.scale) : e.x * p),
				y = (typeof (<MouseEvent>e).clientY !== 'undefined' ? (((<MouseEvent>e).clientY - bounds.top) / this.scale) : e.y * p),
				id = 'marker_' + Math.random(),
				marker = document.createElement('img');

			marker.src = '/assets/images/marker.png';
			marker.className = 'marker';
			marker.id = id;
			if (typeof (<any>e).location_nr !== 'undefined') {
				marker.setAttribute('data-location-nr', (<any>e).location_nr);
			}
			marker.style.position = 'absolute';
			marker.style.top = (y - (41 * p)) + 'px';
			marker.style.left = (x - (12 * p)) + 'px';
			marker.style.width = (25 * p) + 'px';
			marker.style.height = (41 * p) + 'px';

			this.mapZoom.nativeElement.appendChild(marker);

			this.markers.push({id: id, x: (x / p), y: (y / p)});

			return resolve();
		});
	}

	deleteMarker(id: number|string): boolean | void {
		if (this.readonly) {
			return false;
		}
		document.getElementById(id + '').remove();

		let index = this.markers.findIndex(d => d.id === id);
		if (index !== -1) {
			this.markers.splice(index, 1);
		}
	}

	onMouseDown(e: MouseEvent): void {
		e.preventDefault();
		let targetElement: HTMLElement = e.target as HTMLElement;

		if (targetElement.nodeName === 'IMG' && targetElement.classList.contains('marker')) {
			this.deleteMarker(targetElement.id);
		} else {
			this.start = {x: e.clientX - this.pointX, y: e.clientY - this.pointY};
			this.panning = true;
			this.clickStart = new Date().getTime();
		}
	}

	onMouseUp(e: MouseEvent): void {
		this.panning = false;
		let ms = (new Date().getTime() - this.clickStart);

		if (ms < 200) {
			this.addMarker(e).then(() => {});
		}
	}

	onMouseMove(e: MouseEvent): void {
		e.preventDefault();
		let targetElement: HTMLElement = e.target as HTMLElement;
		if (targetElement.nodeName === 'IMG' && targetElement.classList.contains('marker')) {
			this.tooltipMessage = targetElement.dataset.locationNr;
			this.tooltip.disabled = false;
			this.tooltip.show();

			if (typeof this.tooltip._overlayRef !== 'undefined') {
				let tip = this.tooltip._overlayRef.overlayElement;
				if (tip) {
					tip.style.left = e.clientX + 'px';
					tip.style.top = e.clientY + 'px';
				}
			}
		} else {
			this.tooltip.hide();
		}

		if (!this.panning) {
			return;
		}
		this.pointX = (e.clientX - this.start.x);
		this.pointY = (e.clientY - this.start.y);
		this.setTransform();
	}

	onMouoseWheelScroll(e: WheelEvent): void {
		e.preventDefault();
		let x = (e.clientX !== 0 ? e.clientX : (this.mapZoom.nativeElement.offsetWidth / 2)),
			y = (e.clientY !== 0 ? e.clientY : (this.mapZoom.nativeElement.offsetHeight / 2)),
			xs = (x - this.pointX) / this.scale,
			ys = (y - this.pointY) / this.scale,
			delta = e.deltaY;
		(delta < 0) ? (this.scale *= 1.2) : (this.scale /= 1.2);
		this.pointX = x - xs * this.scale;
		this.pointY = y - ys * this.scale;

		this.setTransform();
	}

	waitForMapImageToLoad() {
		return new Promise(resolve => {
			if (this.mapImage.nativeElement.complete) {
				return resolve();
			}
			this.mapImage.nativeElement.onload = () => {
				let go = true,
					x = 0;

				while (go === true) {
					let w = this.mapImage.nativeElement.width,
						h = this.mapImage.nativeElement.height;

					if (x > 1000 || (w === this.loadImgWidth && h === this.loadImgHeight)) {
						go = false;
					}

					x++;
				}
				return resolve();
			};
			this.mapImage.nativeElement.onerror = () => resolve();
		});
	}
}
