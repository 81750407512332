import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../_pipes/angular2-material-datepicker-custom-date';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import {ApiService} from '../../../_interceptors/api.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {MultipleEmailsValidator} from '../../../_validators/multiple-emails';

@Component({
	selector: 'app-reports-send-to-email',
	templateUrl: './reports-send-to-email.component.html',
	styleUrls: ['./reports-send-to-email.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class ReportsSendToEmailComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public report: Reports = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	public reportSendToClientForm = this.formBuilder.group({
		custom_contacts: ['', MultipleEmailsValidator()],
		report_format: ['pdf'],
		message: ['']
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private apiService: ApiService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportSendToClientForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_id + '/view');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getReport(this.report_id).then((details: Reports) => {
			if (typeof details !== 'undefined') {
				this.report = details;

				this.pageTitleService.setTitle(details.report_nr + ' | Send to email | ' + environment.webappName);
				this.pageTitleService.setPageTitle(details.report_nr);

				this.dataLoaded = true;
			} else {
				this.missingData.push('Rapportage');
			}
		});
	}
}
