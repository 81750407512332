import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {LoadingOverlayService} from './loading-overlay.service';
import {ApiService} from '../_interceptors/api.service';
import {SnackbarService} from './snackbar.service';
import {ServerResponse} from '../_interfaces/server.response';
import {PreviousUrlService} from './previous-url.service';
import {Reports} from '../_interfaces/reports/reports';
import {ReportsComments} from '../_interfaces/reports/reports-comments';
import {ReportsBuildings} from '../_interfaces/reports/reports-buildings';
import {ReportsShortcomingsCategories} from '../_interfaces/reports/reports-shortcomings-categories';
import {ReportsShortcomingsSubcategories} from '../_interfaces/reports/reports-shortcomings-subcategories';
import {ReportsShortcomingsTypes} from '../_interfaces/reports/reports-shortcomings-types';
import {ReportsShortcomingsActions} from '../_interfaces/reports/reports-shortcomings-actions';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SimpleModalDialogComponent} from '../core/simple-modal/simple-modal.component';
import {IndexedDBService} from './indexeddb.service';
import {ReportsAttachments} from '../_interfaces/reports/reports-attachments';
import * as moment from 'moment';
import {AuthenticationService} from './authentication.service';
import {ReportsBuildingsReports} from '../_interfaces/reports/reports-buildings-reports';
import {LocalStorageService} from './local-storage.service';
import {ReportsCompliance, ReportsCompliances} from '../_interfaces/reports/reports-compliances';
import {ReportsIkeaPalletOption, ReportsIkeaPalletReport} from '../_interfaces/reports/reports-ikea-pallet-report';

@Injectable()

export class ReportsService {

	public errors: Subject<any> = new BehaviorSubject(null);

	constructor(private apiService: ApiService,
				private indexedDBService: IndexedDBService,
				private dialogService: MatDialog,
				private overlayService: LoadingOverlayService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private authenticationService: AuthenticationService,
				private localStorageService: LocalStorageService,
				private router: Router) {
	}

	getShortcomingCategories(): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsShortcomingsCategories[] = [];
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsCategories).then((categoriesDB: ReportsShortcomingsCategories[]) => {
						if (typeof categoriesDB !== 'undefined') {
							collection = categoriesDB.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
							resolve({
								recordsTotal: collection.length,
								data: collection
							});
						} else {
							this.syncCategories().then((categories: ReportsShortcomingsCategories[]) => {
								collection = categories.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								if (typeof categories !== 'undefined') {
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								resolve(undefined);
								throw new Error(error);
							});
						}
					}, () => {
						this.syncCategories().then((categories: ReportsShortcomingsCategories[]) => {
							if (typeof categories !== 'undefined') {
								collection = categories.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								resolve(undefined);
							}
						}).catch(error => {
							resolve(undefined);
							throw new Error(error);
						});
					});
				}
			});
		});
	}

	getShortcomingSubcategories(category_id: number): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsShortcomingsSubcategories[] = [];
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsSubcategories).then((subcategoriesDB: ReportsShortcomingsSubcategories[]) => {
						if (typeof subcategoriesDB !== 'undefined') {
							collection = subcategoriesDB.filter(subcategory => subcategory.category_id === category_id);
							collection.sort((a, b) => a.subcategory_name.toLowerCase() > b.subcategory_name.toLowerCase() ? 1 : -1);
							resolve({
								recordsTotal: collection.length,
								data: collection
							});
						} else {
							this.syncSubcategories().then((subcategories: ReportsShortcomingsSubcategories[]) => {
								if (typeof subcategories !== 'undefined') {
									collection = subcategories.filter(subcategory => subcategory.category_id === category_id);
									collection.sort((a, b) => a.subcategory_name.toLowerCase() > b.subcategory_name.toLowerCase() ? 1 : -1);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								resolve(undefined);
								throw new Error(error);
							});
						}
					}, () => {
						this.syncSubcategories().then((subcategories: ReportsShortcomingsSubcategories[]) => {
							if (typeof subcategories !== 'undefined') {
								collection = subcategories.filter(subcategory => subcategory.category_id === category_id);
								collection.sort((a, b) => a.subcategory_name.toLowerCase() > b.subcategory_name.toLowerCase() ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								resolve(undefined);
							}
						}).catch(error => {
							resolve(undefined);
							throw new Error(error);
						});
					});
				}
			});
		});
	}

	getShortcomingTypes(category_id: number, subcategory_id: number): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsShortcomingsTypes[] = [];
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsTypes).then((typesDB: ReportsShortcomingsTypes[]) => {
						if (typeof typesDB !== 'undefined') {
							collection = typesDB.filter(type => type.category_id === category_id && type.subcategory_id === subcategory_id);
							collection.sort((a, b) => a.type_name.toLowerCase() > b.type_name.toLowerCase() ? 1 : -1);
							resolve({
								recordsTotal: collection.length,
								data: collection
							});
						} else {
							this.syncTypes().then((types: ReportsShortcomingsTypes[]) => {
								if (typeof types !== 'undefined') {
									collection = types.filter(type => type.category_id === category_id && type.subcategory_id === subcategory_id);
									collection.sort((a, b) => a.type_name.toLowerCase() > b.type_name.toLowerCase() ? 1 : -1);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								resolve(undefined);
								throw new Error(error);
							});
						}
					}, () => {
						this.syncTypes().then((types: ReportsShortcomingsTypes[]) => {
							if (typeof types !== 'undefined') {
								collection = types.filter(type => type.category_id === category_id && type.subcategory_id === subcategory_id);
								collection.sort((a, b) => a.type_name.toLowerCase() > b.type_name.toLowerCase() ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								resolve(undefined);
							}
						}).catch(error => {
							resolve(undefined);
							throw new Error(error);
						});
					});
				}
			});
		});
	}

	getShortcomingActions(category_id: number, subcategory_id: number): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsShortcomingsActions[] = [];
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsActions).then((actionsDB: ReportsShortcomingsActions[]) => {
						if (typeof actionsDB !== 'undefined') {
							collection = actionsDB.filter(action => action.category_id === category_id && action.subcategory_id === subcategory_id);
							collection.sort((a, b) => a.action_name.toLowerCase() > b.action_name.toLowerCase() ? 1 : -1);
							resolve({
								recordsTotal: collection.length,
								data: collection
							});
						} else {
							this.syncActions().then((actions: ReportsShortcomingsActions[]) => {
								if (typeof actions !== 'undefined') {
									collection = actions.filter(action => action.category_id === category_id && action.subcategory_id === subcategory_id);
									collection.sort((a, b) => a.action_name.toLowerCase() > b.action_name.toLowerCase() ? 1 : -1);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								resolve(undefined);
								throw new Error(error);
							});
						}
					}, () => {
						this.syncActions().then((actions: ReportsShortcomingsActions[]) => {
							if (typeof actions !== 'undefined') {
								collection = actions.filter(action => action.category_id === category_id && action.subcategory_id === subcategory_id);
								collection.sort((a, b) => a.action_name.toLowerCase() > b.action_name.toLowerCase() ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								resolve(undefined);
							}
						}).catch(error => {
							resolve(undefined);
							throw new Error(error);
						});
					});
				}
			});
		});
	}

	getPalletOptions(): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsIkeaPalletOption[] = [];
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsIkeaPalletOptions).then((palletOptionsDB: ReportsIkeaPalletOption[]) => {
						if (typeof palletOptionsDB !== 'undefined') {
							collection = palletOptionsDB.sort((a, b) => a.option_name > b.option_name ? 1 : -1);
							resolve({
								recordsTotal: collection.length,
								data: collection
							});
						} else {
							this.syncPalletOptions().then((categories: ReportsIkeaPalletOption[]) => {
								collection = categories.sort((a, b) => a.option_name > b.option_name ? 1 : -1);
								if (typeof categories !== 'undefined') {
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								resolve(undefined);
								throw new Error(error);
							});
						}
					}, () => {
						this.syncPalletOptions().then((categories: ReportsIkeaPalletOption[]) => {
							if (typeof categories !== 'undefined') {
								collection = categories.sort((a, b) => a.option_name > b.option_name ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								resolve(undefined);
							}
						}).catch(error => {
							resolve(undefined);
							throw new Error(error);
						});
					});
				}
			});
		});
	}


	getReports(formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getReportsWhenOnline(formData));
			} else {
				resolve(this.getReportsWhenOffline(formData));
			}
		});
	}

	getReportsWhenOffline(formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let results: Reports[] = [],
						collection: Reports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReports).then((reportsDB: Reports[]) => {
						if (typeof reportsDB !== 'undefined') {
							collection = reportsDB.filter(reports => typeof reports.indexedDBMethod === 'undefined' || typeof reports.indexedDBMethod !== 'undefined' && reports.indexedDBMethod !== '3-delete');
							collection.sort((a, b) => a.created > b.created ? -1 : 1);

							if (searchString !== null) {
								collection = collection.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								results = collection.slice(startIndex, endIndex);
							} else {
								results = collection.slice();
							}
							resolve({
								recordsTotal: collection.length,
								data: results
							});
						} else {
							resolve(undefined);
						}
					}, (error) => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getReportsWhenOnline(formData = null): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get(this.indexedDBService.tableReports, formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getReport(report_id: number): Promise<Reports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getReportWhenOnline(report_id));
			} else {
				resolve(this.getReportWhenOffline(report_id));
			}
		});
	}

	getReportWhenOffline(report_id: number): Promise<Reports> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then((report: Reports) => {
						if (typeof report !== 'undefined') {
							resolve(report);
						} else {
							resolve(undefined);
						}
					}, (error) => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getReportWhenOnline(report_id: number): Promise<Reports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<Reports>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	createReport(formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post(this.indexedDBService.tableReports, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Report saved!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReports, <Reports>data.data).then(() => {
						}, error => new Error(error));

						this.router.navigate(['/reports/' + data.data.id + '/view']).then(() => {
						});
					} else {
						this.router.navigate(['/reports']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			let _userSubscription = this.authenticationService.user.subscribe(user => {
				formData['id'] = moment().valueOf();
				formData['account_user_id'] = user.id;
				formData['inspector'] = user.display_name;
				formData['company_name'] = user.company_name;
				formData['created'] = moment().valueOf();
				if (formData['type'] !== 'warehouse') {
					formData['report_nr'] = formData['week'];
				}
				delete formData['account_user_id_filterString'];
				delete formData['client_filterString'];

				this.indexedDBService.database.add(this.indexedDBService.tableReports, formData).then(() => {
					this.snackbarService.success('Report saved!');

					formData['indexedDBMethod'] = '1-create';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, formData).then(() => {
					}, error => new Error(error));

					this.router.navigate(['/reports/' + formData['id'] + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
					throw new Error(error);
				});
			});
			_userSubscription.unsubscribe();
		}
	}

	updateReport(report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Report saved!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReports, <Reports>data.data).then(() => {
						}, error => new Error(error));

						this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
						});
					} else {
						this.router.navigate(['/reports']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReports, 'id', report_id).then(report => {
				delete formData['account_user_id_filterString'];
				delete formData['client_filterString'];
				formData['report_nr'] = formData['week'];
				let newReport: Reports = {...report, ...formData};
				newReport['updated'] = moment().valueOf();
				this.indexedDBService.database.update(this.indexedDBService.tableReports, newReport).then(() => {
					this.snackbarService.success('Report saved!');

					newReport['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, newReport).then(() => {
					}, error => new Error(error));

					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
					throw new Error(error);
				});
			}, error => new Error(error));
		}
	}

	deleteReport(report_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze rapportage wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.indexedDBService.database.delete(this.indexedDBService.tableReports, report_id).then(() => {
								}, error => new Error(error));

								this.snackbarService.success('Report deleted.');
								this.cleanupAfterReportDeletion([report_id]);
								resolve(true);
							} else {
								this.snackbarService.error('An error occurred while loading the data, please try again.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReports, report_id).then(() => {
							this.snackbarService.success('Report deleted.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, {
								id: report_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
							}, error => new Error(error));

							this.cleanupAfterReportDeletion([report_id]);
							resolve(true);
						}, error => {
							this.snackbarService.error('An error occurred while loading the data, please try again.');
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	sendToEmail(report_id: number, formData): void {
		if (formData['custom_contacts'] !== '') {
			if (this.apiService.isOnline) {
				this.apiService.post('reports/' + report_id + '/send-to-email', formData).then((data: ServerResponse) => {
					if (data.success === true) {
						this.snackbarService.success('Report sent!');
						this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
						});
					} else if (typeof data.error !== 'undefined') {
						this.errors.next(data.error);
					} else {
						this.snackbarService.error('An error occurred while loading the data, please try again.');
					}
				});
			} else {
				formData['report_id'] = report_id;
				this.indexedDBService.database.add(this.indexedDBService.tableReportsSendToClient, formData).then(() => {
					this.snackbarService.success('Report will be sent when there is an internet connection again.');
					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
					throw new Error(error);
				});
			}
		} else {
			this.snackbarService.warning('Select at least one contact person or enter an email address manually.');
		}
	}

	copyReport(report_id: number, type: string = 'red') {
		if (this.apiService.isOnline) {
			this.apiService.get('reports/' + report_id + '/copy/' + type).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.data !== 'undefined') {
						if (typeof data.data.link !== 'undefined') {
							let promises = [
								this.indexedDBService.syncReports(),
								this.indexedDBService.syncReportsAttachments(),
								this.indexedDBService.syncReportsComments(),
								this.indexedDBService.syncReportsBuildings(),
								this.indexedDBService.syncReportsBuildingsReports(),
								this.indexedDBService.syncTypes(),
								this.indexedDBService.syncActions()
							];
							Promise.all(promises).then(() => {
								this.previousUrlService.goTo(data.data.link);
								this.snackbarService.success('Report copied successfully!');
							});
						}
					}
				}
			});
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
/*
			this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report => {
				if (typeof report !== 'undefined') {
					let new_report_id = moment().valueOf(),
						promises = [];

					report['parent_report_id'] = report['id'];
					report['id'] = new_report_id;
					report['created'] = moment().valueOf();
					report['status'] = 'open';
					report['status_text'] = 'Open';
					report['report_nr'] = report['report_nr'] + '-Offline';
					report['is_offline'] = 'yes';
					delete report['updated'];

					// save report
					promises.push(new Promise(resolve0 => {
						// console.log(JSON.parse(JSON.stringify(report)));
						this.indexedDBService.database.add(this.indexedDBService.tableReports, report).then(() => {
							let promises1 = [];

							report['indexedDBMethod'] = '1-create';

							promises1.push(new Promise(resolve1 => {
								// console.log(JSON.parse(JSON.stringify(report)));
								this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, report).then(() => {
									let promises2 = [];

									// copy report's attachments
									promises2.push(new Promise(resolve2 => {
										this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachments).then(attachmentsDB => {
											let promises3 = [];
											if (typeof attachmentsDB !== 'undefined') {
												let attachments = attachmentsDB.filter(data => data.report_id === report_id);
												attachments.forEach(attachment => {
													let id = JSON.parse(JSON.stringify(attachment.id));
													attachment['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
													attachment['created'] = report['created'];
													attachment['report_id'] = report['id'];
													delete attachment['download_url'];

													promises3.push(new Promise(resolve3 => {
														// console.log(JSON.parse(JSON.stringify(attachment)));
														this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachments, attachment).then(() => {
															attachment['copy_from_attachment_id'] = id;
															attachment['copy_from_report_id'] = report_id;
															attachment['indexedDBMethod'] = '0-copy';

															// console.log(JSON.parse(JSON.stringify(attachment)));
															this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachmentsPush, attachment).then(() => {
																resolve3();
															});
														});
													}));
												});
											}

											Promise.all(promises3).then(() => {
												resolve2();
											});
										});
									}));

									// copy report's buildings
									promises2.push(new Promise(resolve2 => {
										this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildingsDB => {
											if (typeof buildingsDB !== 'undefined') {
												let buildings = buildingsDB.filter(data => data.report_id === report_id),
													promises3 = [];

												buildings.forEach(building => {
													let building_id = parseInt(JSON.parse(JSON.stringify(building['id'])), 10);
													building['report_id'] = report['id'];
													building['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));

													if (typeof building.lib_particular_id !== 'undefined') {
														if (building.lib_particular_id.length === 0) {
															building['lib_particular_id'] = [];
														}
													}

													promises3.push(new Promise(resolve3 => {
														// console.log(JSON.parse(JSON.stringify(building)));
														this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildings, building).then(() => {
															let promises4 = [];

															building['indexedDBMethod'] = '1-create';
															promises4.push(new Promise(resolve4 => {
																// console.log(JSON.parse(JSON.stringify(building)));
																this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, building).then(() => {
																	resolve4();
																});
															}));

															// copy building reports
															promises4.push(new Promise(resolve4 => {
																this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then(buildingReportsDB => {
																	let promises5 = [];

																	if (typeof buildingReportsDB !== 'undefined') {
																		let buildingReports = buildingReportsDB.filter(data => data.report_id === report_id && data.building_id === building_id);
																		buildingReports.forEach(buildingReport => {
																			buildingReport['report_id'] = report['id'];
																			buildingReport['building_id'] = building['id'];
																			buildingReport['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
																			buildingReport['indexedDBMethod'] = '1-create';
																			delete buildingReport['photos'];

																			promises5.push(new Promise(resolve5 => {
																				// console.log(JSON.parse(JSON.stringify(buildingReport)));
																				this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReports, buildingReport).then(() => {
																					let promises6 = [];

																					promises6.push(new Promise(resolve6 => {
																						// console.log(JSON.parse(JSON.stringify(buildingReport)));
																						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, buildingReport).then(() => {
																							resolve6();
																						});
																					}));

																					Promise.all(promises6).then(() => {
																						resolve5();
																					});
																				}).catch(error => console.log(error));
																			}));
																		});
																	}

																	Promise.all(promises5).then(() => {
																		resolve4();
																	});
																});
															}));

															Promise.all(promises4).then(() => {
																resolve3();
															});
														});
													}));
												});

												Promise.all(promises3).then(() => {
													resolve2();
												});
											} else {
												resolve2();
											}
										});
									}));

									Promise.all(promises2).then(() => {
										resolve1();
									});
								});
							}));

							Promise.all(promises1).then(() => {
								resolve0();
							});
						});
					}));

					Promise.all(promises).then(() => {
						// console.log('copy done, redirect ...', new_report_id);
						this.router.navigate(['/reports/' + new_report_id + '/view']).then(() => {
							this.snackbarService.success('Rapport kopiëren geslaagd!');
							this.indexedDBService.database.delete('reports_reinspections', report_id);
						});
					}).catch(() => {
						console.log('error, cleaning up ...');
						this.indexedDBService.database.delete(this.indexedDBService.tableReports, report['id']);
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsPush, report['id']);
						this.deleteReportAttachments(report['id']).then(() => {
						});
						this.deleteReportBuildings(report['id']).then(() => {
						});
						this.deleteReportBuildingsReports(report['id']).then(() => {
						});
					});
				}
			});
*/
		}
	}

	getAttachments(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getAttachmentsWhenOnline(report_id, formData));
			} else {
				resolve(this.getAttachmentsWhenOffline(report_id, formData));
			}
		});
	}

	getAttachmentsWhenOffline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsAttachments[] = [],
						collectionData: ReportsAttachments[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachments).then((attachmentsDB: ReportsAttachments[]) => {
						if (typeof attachmentsDB !== 'undefined') {
							collectionData = attachmentsDB.filter(attachments => attachments.report_id === report_id && (typeof attachments.indexedDBMethod === 'undefined' || typeof attachments.indexedDBMethod !== 'undefined' && attachments.indexedDBMethod !== '3-delete'));
							collectionData.sort((a, b) => a.created > b.created ? -1 : 1);

							if (searchString !== null) {
								collectionData = collectionData.filter(attachments => attachments.title.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getAttachmentsWhenOnline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/attachments', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	createAttachment(report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/attachments', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Attachment saved.');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachments, <ReportsAttachments>data.data).then(() => {
						}, error => new Error(error));
					}

					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			let _authenticationService = this.authenticationService.user.first().subscribe(user => {
				formData['id'] = moment().valueOf();
				formData['indexedDBMethod'] = '1-create';
				formData['report_id'] = report_id;
				formData['created'] = moment().valueOf();

				let newFormData = {
					id: formData.id,
					report_id: report_id,
					filename: formData.filename._files[0].name,
					original_filename: formData.filename._files[0].name,
					title: formData['title'],
					description: formData['description'],
					inspector: user.display_name,
					created: formData['created']
				};

				this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachments, newFormData).then(() => {
					this.snackbarService.success('Attachment saved.');

					this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachmentsPush, <ReportsAttachments>formData);

					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
					throw new Error(error);
				});
			});
			_authenticationService.unsubscribe();
		}
	}

	deleteAttachment(report_id: number, attachment_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Are you sure you want to delete this attachment?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_id + '/attachments/' + attachment_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Attachment deleted.');

								this.indexedDBService.database.delete(this.indexedDBService.tableReportsAttachments, attachment_id).then(
									() => {
									}, error => new Error(error));

								resolve(true);
							} else {
								this.snackbarService.error('An error occurred while loading the data, please try again.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsAttachments, attachment_id).then(() => {
							this.snackbarService.success('Attachment deleted.');

							let formData = {
								'report_id': report_id,
								'id': attachment_id,
								'indexedDBMethod': '3-delete'
							};
							this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachmentsPush, formData).then(
								() => {
								}, error => new Error(error));

						}, error => {
							this.snackbarService.error('An error occurred while loading the data, please try again.');
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	getComments(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getCommentsWhenOnline(report_id, formData));
			} else {
				resolve(this.getCommentsWhenOffline(report_id, formData));
			}
		});
	}

	getCommentsWhenOffline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsComments[] = [],
						collectionData: ReportsComments[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsComments).then((commentsDB: ReportsComments[]) => {
						if (typeof commentsDB !== 'undefined') {
							collectionData = commentsDB.filter(comments => comments.report_id === report_id && (typeof comments.indexedDBMethod === 'undefined' || typeof comments.indexedDBMethod !== 'undefined' && comments.indexedDBMethod !== '3-delete'));
							collectionData.sort((a, b) => a.created > b.created ? 1 : -1);

							if (searchString !== null) {
								collectionData = collectionData.filter(comments => comments.inspector.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getCommentsWhenOnline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/comments', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getComment(report_id: number, comment_id: number): Promise<ReportsComments> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getCommentWhenOnline(report_id, comment_id));
			} else {
				resolve(this.getCommentWhenOffline(report_id, comment_id));
			}
		});
	}

	getCommentWhenOffline(report_id: number, comment_id: number): Promise<ReportsComments> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsComments, comment_id).then((commentDB: ReportsComments) => {
						if (typeof commentDB !== 'undefined') {
							resolve(commentDB);
						} else {
							resolve(undefined);
						}
					}, () => {
						resolve(undefined);
					});
				}
			});
		});
	}

	getCommentWhenOnline(report_id: number, comment_id: number): Promise<ReportsComments> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/comments/' + comment_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsComments>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	createComment(report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/comments', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Comment saved.');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReportsComments, <ReportsComments>data.data).then(() => {
						}, error => new Error(error));
					}

					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			let _authenticationService = this.authenticationService.user.first().subscribe(user => {
				formData['id'] = moment().valueOf();
				formData['report_id'] = report_id;
				formData['account_user_id'] = user.id;
				formData['inspector'] = user.display_name;
				formData['created'] = moment().valueOf();

				this.indexedDBService.database.add(this.indexedDBService.tableReportsComments, <ReportsComments>formData).then(() => {
					this.snackbarService.success('Comment saved.');

					formData['indexedDBMethod'] = '1-create';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsCommentsPush, formData).then(() => {
					}, error => new Error(error));

					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				}, error => new Error(error));
			});
			_authenticationService.unsubscribe();
		}
	}

	updateComment(report_id: number, comment_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/comments/' + comment_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Comment saved.');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReportsComments, <ReportsComments>data.data).then(() => {
						}, error => new Error(error));
					}

					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsComments, 'id', comment_id).then(comment => {
				comment['comment'] = formData['comment'];
				this.indexedDBService.database.update(this.indexedDBService.tableReportsComments, <ReportsComments>comment).then(() => {
					this.snackbarService.success('Comment saved.');

					comment['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsCommentsPush, comment).then(() => {
					}, error => new Error(error));

					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {
					});
				}, error => new Error(error));
			}, error => new Error(error));
		}
	}

	getBuildings(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingsWhenOnline(report_id, formData));
			} else {
				resolve(this.getBuildingsWhenOffline(report_id, formData));
			}
		});
	}

	getBuildingsWhenOffline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildings[] = [],
						collectionData: ReportsBuildings[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[3][search][value]'] !== 'undefined') {
							if (formData['columns[3][search][value]'] !== null && formData['columns[3][search][value]'] !== '') {
								searchString = formData['columns[3][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then((reportBuildingsDB: ReportsBuildings[]) => {
						if (typeof reportBuildingsDB !== 'undefined') {
							collectionData = reportBuildingsDB.filter(collection2 => collection2.report_id === report_id);
							collectionData.sort((a, b) => a.sequence > b.sequence ? -1 : 1);

							if (searchString !== null) {
								collectionData = collectionData.filter(collection2 => collection2.name.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingsWhenOnline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/buildings', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getBuilding(report_id: number, formData): Promise<ReportsBuildings> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingWhenOnline(report_id, formData));
			} else {
				resolve(this.getBuildingWhenOffline(report_id, formData));
			}
		});
	}

	getBuildingWhenOffline(report_id: number, building_id: number): Promise<ReportsBuildings> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsBuildings, building_id).then((reportBuildingDB: ReportsBuildings) => {
						if (typeof reportBuildingDB !== 'undefined') {
							resolve(reportBuildingDB);
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingWhenOnline(report_id: number, building_id: number): Promise<ReportsBuildings> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/buildings/' + building_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsBuildings>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	createBuilding(report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/buildings', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Building saved.');
					if (typeof data.data !== 'undefined') {

						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildings, <ReportsBuildings>data.data).then(() => {
						}, error => new Error(error));

						this.router.navigate(['/reports/' + report_id + '/buildings/' + data.data.id + '/reports']).then(() => {
						});
					} else {
						this.router.navigate(['/reports/' + report_id + '/buildings']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			formData['id'] = moment().valueOf();
			formData['report_id'] = report_id;
			formData['created'] = moment().valueOf();
			formData['sequence'] = 1;
			delete formData['query'];
			this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildings => {
				if (typeof buildings !== 'undefined') {
					buildings.forEach(building => {
						if (building.report_id === report_id) {
							if (typeof building.sequence !== 'undefined') {
								if (formData['sequence'] <= building.sequence) {
									formData['sequence'] = parseInt(building.sequence, 10) + 1;
								}
							}
						}
					});
				}

				this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildings, formData).then(() => {
					this.snackbarService.success('Building saved.');

					formData['indexedDBMethod'] = '1-create';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, formData).then(() => {
					}, error => new Error(error));

					this.router.navigate(['/reports/' + report_id + '/buildings/' + formData.id + '/reports']).then();
				}, error => new Error(error));
			}, error => new Error(error));
		}
	}

	updateBuilding(report_id: number, building_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/buildings/' + building_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Building saved.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildings, <ReportsBuildings>data.data).then(() => {
						}, error => new Error(error));
						this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
						});
					} else {
						this.router.navigate(['/reports/' + report_id + '/buildings']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildings, 'id', building_id).then(building => {
				delete formData['query'];
				let newBuilding = {...building, ...formData};
				newBuilding['updated'] = moment().valueOf();
				this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildings, newBuilding).then(() => {
					this.snackbarService.success('Building saved.');

					newBuilding['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, newBuilding).then(() => {
					}, error => new Error(error));

					this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then();
				}, error => new Error(error));
			}, error => new Error(error));
		}
	}

	deleteBuilding(report_id: number, building_id: number): Promise<boolean> {
		return new Promise(resolve => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Are you sure you want to delete this building?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_id + '/buildings/' + building_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Building deleted.');
								this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildings, building_id).then(() => {
									resolve(true);
								}, error => {
									resolve(true);
									throw new Error(error);
								});
							} else {
								this.snackbarService.error('Error occurred while deleting building.');
								resolve(false);
							}
						}, error => {
							resolve(undefined);
							throw new Error(error);
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildings, building_id).then(() => {
							this.snackbarService.success('Building deleted.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, {
								id: building_id,
								report_id: report_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
								this.deleteReportBuildingsReports(report_id, building_id).then(() => {
									resolve(true);
								});
							}, error => {
								resolve(undefined);
								throw new Error(error);
							});
						}, error => {
							resolve(undefined);
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	getBuildingReports(report_id: number, building_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingReportsWhenOnline(report_id, building_id, formData));
			} else {
				resolve(this.getBuildingReportsWhenOffline(report_id, building_id, formData));
			}
		});
	}

	getBuildingReportsWhenOffline(report_id: number, building_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildingsReports[] = [],
						collectionData: ReportsBuildingsReports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							endIndex += startIndex;
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then((buildingReportsDB: ReportsBuildingsReports[]) => {
						if (typeof buildingReportsDB !== 'undefined') {
							collectionData = buildingReportsDB.filter(collection2 => collection2.report_id === report_id && collection2.building_id === building_id && (typeof collection2.indexedDBMethod === 'undefined' || typeof collection2.indexedDBMethod !== 'undefined' && collection2.indexedDBMethod !== '3-delete'));
							collectionData.sort((a, b) => a.location_nr > b.location_nr ? 1 : -1);

							if (searchString !== null) {
								collectionData = collectionData.filter(collection2 => collection2.location_nr.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingReportsWhenOnline(report_id: number, building_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/buildings/' + building_id + '/reports', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getBuildingReport(report_id: number, building_id: number, building_report_id): Promise<ReportsBuildingsReports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingReportWhenOnline(report_id, building_id, building_report_id));
			} else {
				resolve(this.getBuildingReportWhenOffline(report_id, building_id, building_report_id));
			}
		});
	}

	getBuildingReportWhenOffline(report_id: number, building_id: number, building_report_id: number): Promise<ReportsBuildingsReports> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsBuildingsReports, building_report_id).then((buildingReportDB: ReportsBuildingsReports) => {
						if (typeof buildingReportDB !== 'undefined') {
							resolve(buildingReportDB);
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingReportWhenOnline(report_id: number, building_id: number, building_report_id: number): Promise<ReportsBuildingsReports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsBuildingsReports>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	createBuildingReport(report_id: number, building_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/buildings/' + building_id + '/reports', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Report saved.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>data.data).then(() => {
						}, error => new Error(error));
					}
					this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			delete formData['photosContainer[]'];

			formData['id'] = moment().valueOf();
			formData['report_id'] = report_id;
			formData['building_id'] = building_id;
			formData['created'] = moment().valueOf();
			formData['created'] = moment().valueOf();
			formData['sequence'] = 1;
			if (typeof formData['warehouse_action_ids'] !== 'undefined') {
				formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(data => data !== false);
			}
			if (typeof formData['warehouse_type_ids'] !== 'undefined') {
				formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(data => data !== false);
			}
			if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
				let tmp = {};
				Object.keys(formData['warehouse_type_field_value']).map(key => {
					if (formData['warehouse_type_field_value'][key] !== '') {
						tmp[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
					}
				});
				formData['warehouse_type_field_value'] = tmp;
			}
			let pushPhotos = [],
				reportPhotos = [];
			if (typeof formData['photos'] !== 'undefined') {
				if (formData['photos'].length) {
					formData['photos'].forEach(photo => {
						let id = crypto['randomUUID']();
						pushPhotos.push({
							id: id,
							file: photo
						});
						reportPhotos.push({
							filename: photo.name,
							id: id,
							offline: true
						});
					});
					formData['photos'] = {
						inspector: reportPhotos
					};
				}
				if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
					delete formData['photos'];
				}
			}

			if (typeof formData['color_code'] !== 'undefined') {
				if (formData['color_code'] === '0') {
					formData['color'] = '#ED7C20';
					formData['color_text'] = 'Oranje';
				} else if (formData['color_code'] === '1') {
					formData['color'] = '#CC0000';
					formData['color_text'] = 'Rood';
				} else if (formData['color_code'] === '2') {
					formData['color'] = '#019342';
					formData['color_text'] = 'Groen';
				}
			}

			this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then(buildingReports => {
				if (typeof buildingReports !== 'undefined') {
					buildingReports.forEach(buildingReport => {
						if (buildingReport.report_id === report_id && buildingReport.building_id === building_id) {
							if (typeof buildingReport.sequence !== 'undefined') {
								if (formData['sequence'] <= buildingReport.sequence) {
									formData['sequence'] = parseInt(buildingReport.sequence, 10) + 1;
								}
							}
						}
					});

					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>formData).then(() => {
						this.snackbarService.success('Report saved.');

						formData['indexedDBMethod'] = '1-create';
						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, formData).then(() => {
						}, error => new Error(error));

						if (pushPhotos.length) {
							let reportPhotos2 = {
								report_id: report_id,
								building_id: building_id,
								building_report_id: formData.id,
								indexedDBMethod: '1-create',
								photos: pushPhotos
							};
							this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(() => {
							}, error => new Error(error));
						}

						this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
						});
					}, error => new Error(error));
				}
			});
		}
	}

	updateBuildingReport(report_id: number, building_id: number, building_report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Report saved.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>data.data).then(() => {
						}, error => new Error(error));
					}

					this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(report => {
				delete formData['photosContainer[]'];
				if (typeof formData['warehouse_action_ids'] !== 'undefined') {
					formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(data => data !== false);
				}
				if (typeof formData['warehouse_type_ids'] !== 'undefined') {
					formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(data => data !== false);
				}
				if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
					let tmp = {};
					Object.keys(formData['warehouse_type_field_value']).map(key => {
						if (formData['warehouse_type_field_value'][key] !== '') {
							tmp[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
						}
					});
					formData['warehouse_type_field_value'] = tmp;
				}

				let pushPhotos = [],
					reportPhotos = [];
				if (typeof formData['photos'] !== 'undefined') {
					if (typeof report['photos'] !== 'undefined') {
						if (typeof report['photos']['inspector'] !== 'undefined') {
							reportPhotos = report['photos']['inspector'];
						}
					}
					if (formData['photos'].length) {
						formData['photos'].forEach(photo => {
							let id = crypto['randomUUID']();
							pushPhotos.push({
								id: id,
								file: photo
							});
							reportPhotos.push({
								filename: photo.name,
								id: id,
								offline: true
							});
						});
						formData['photos'] = {
							inspector: reportPhotos
						};
					}
					if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
						delete formData['photos'];
					}
				}

				if (typeof formData['color_code'] !== 'undefined') {
					if (formData['color_code'] === '0') {
						formData['color'] = '#ED7C20';
						formData['color_text'] = 'Oranje';
					} else if (formData['color_code'] === '1') {
						formData['color'] = '#CC0000';
						formData['color_text'] = 'Rood';
					} else if (formData['color_code'] === '2') {
						formData['color'] = '#019342';
						formData['color_text'] = 'Groen';
					}
				}

				if (typeof formData['client_fixed_status'] !== 'undefined') {
					formData['is_resolved'] = formData['client_fixed_status'];
				}

				let newReport = {...report, ...formData};
				newReport['updated'] = moment().valueOf();

				this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>newReport).then(() => {
					this.snackbarService.success('Report saved.');

					newReport['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, newReport).then(() => {
					}, error => new Error(error));

					if (pushPhotos.length) {
						let reportPhotos2 = {
							report_id: report_id,
							building_id: building_id,
							building_report_id: building_report_id,
							indexedDBMethod: '1-create',
							photos: pushPhotos
						};
						this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(photos => {
							if (typeof photos !== 'undefined') {
								if (typeof photos.photos !== 'undefined') {
									photos.photos = photos.photos.concat(pushPhotos);
									this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReportsPhotos, photos).then(() => {
									}, error => new Error(error));
								} else {
									this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(() => {
									}, error => new Error(error));
								}
							} else {
								this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(() => {
								}, error => new Error(error));
							}
						}, error => new Error(error));
					}

					this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				}, error => new Error(error));
			}, error => new Error(error));
		}
	}

	deleteBuildingReport(report_id: number, building_id: number, building_report_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze contactpersoon wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Report deleted.');
								resolve(true);

								this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(() => {
								}, error => new Error(error));
							} else {
								this.snackbarService.error('An error occurred while loading the data, please try again.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(() => {
							this.snackbarService.success('Report deleted.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, {
								id: building_report_id,
								report_id: report_id,
								building_id: building_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
								resolve(true);
							}, error => {
								resolve(undefined);
								throw new Error(error);
							});
						}, error => {
							resolve(undefined);
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	deleteBuildingReportPhoto(report_id: number, building_id: number, building_report_id: number, photo_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Are you sure you want to delete this photo?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/delete-image/' + photo_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Photo deleted.');
								resolve(true);
							} else {
								this.snackbarService.error('An error occurred while loading the data, please try again.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(report => {
							let was_photo_removed: boolean = false,
							promise = [];

							if (typeof report !== 'undefined') {
								if (typeof report.photos !== 'undefined') {
									if (report.photos.length) {
										let photo = report.photos.filter(data => data.id === photo_id);
										if (photo.length) {
											photo = report.photos.indexOf(photo[0]);
											report.photos.splice(photo, 1);
											if (report.photos.length) {
												promise.push(this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReportsPhotos, report).then(() => {
													was_photo_removed = true;
												}, error => new Error(error)));
											} else {
												promise.push(this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(() => {
													was_photo_removed = true;
												}, error => new Error(error)));
											}
											this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(report2 => {
												if (typeof report2 !== 'undefined') {
													if (typeof report2.photos !== 'undefined') {
														if (typeof report2.photos.inspector !== 'undefined') {
															if (report2.photos.inspector.length) {
																let photo2 = report2.photos.inspector.filter(data => data.id === photo_id);
																if (photo2.length) {
																	photo2 = report2.photos.inspector.indexOf(photo2[0]);
																	report2.photos.inspector.splice(photo2, 1);
																	this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReports, report2).then(() => {
																		this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReportsPush).then(reports3 => {
																			if (typeof reports3 !== 'undefined') {
																				let report3 = reports3.filter(data => data.id === building_report_id)[0];
																				if (typeof report3.photos !== 'undefined') {
																					if (typeof report3.photos.inspector !== 'undefined') {
																						if (report3.photos.inspector.length) {
																							let photo3 = report3.photos.inspector.filter(data => data.id === photo_id);
																							if (photo3.length) {
																								photo3 = report3.photos.inspector.indexOf(photo3[0]);
																								report3.photos.inspector.splice(photo3, 1);
																								this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReportsPush, report3).then(() => {
																								}, error => new Error(error));
																							}
																						}
																					}
																				}
																			}

																		});
																	}, error => new Error(error));
																}
															}
														}
													}
												}
											});
											this.snackbarService.success('Photo deleted.');
										}
									}
								}
							}

							Promise.all(promise).then(() => {
								if (was_photo_removed === false) {
									let formData = {
										id: photo_id,
										report_id: report_id,
										building_id: building_id,
										building_report_id: building_report_id,
										indexedDBMethod: '3-delete'
									};
									this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, formData).then(() => {
										this.snackbarService.success('Photo deleted.');
										resolve(true);
									}, error => new Error(error));
								}
							});

							resolve(true);
						}, error => new Error(error));
					}
				}
			});
		});
	}

	getOfflinePhotos(report_id: number, building_id: number, building_report_id: number, photo_id: number) {
		return new Promise(resolve => {
			this.indexedDBService.database.getAll(this.indexedDBService.tableReportsBuildingsReportsPhotos).then(reports => {
				reports.forEach(report => {
					if (report.report_id === report_id && report.building_id === building_id && report.building_report_id === building_report_id && typeof report.photos !== 'undefined') {
						if (typeof report.photos !== 'undefined') {
							let photo = report.photos.filter(data => data.id === photo_id)[0],
								file = photo.file,
								reader = new FileReader();

							reader.onloadend = () => {
								resolve(reader.result);
							};
							reader.readAsDataURL(file);
						}
					}
				});
			}, error => {
				resolve(undefined);
				throw new Error(error);
			});
		});
	}

	getCompliances(report_id: number): Promise<ReportsCompliances[]> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsCompliances).then((reportCompliancesDB: ReportsCompliance[]) => {
						let compliances = [];
						for (let i = 0; i < 7; i++) {
							let compliance = {
								weekday: i,
								day: (i === 0 ? 'Monday' : (
										i === 1 ? 'Tuesday' : (
											i === 2 ? 'Wednesday' : (
												i === 3 ? 'Thursday' : (
													i === 4 ? 'Friday' : (
														i === 5 ? 'Saturday' : (
															i === 6 ? 'Sunday' : '-'
														)
													)
												)
											)
										)
									)
								),
								status: false
							};
							if (typeof reportCompliancesDB !== 'undefined') {
								reportCompliancesDB.forEach((compliances: ReportsCompliance) => {
									if (compliances.report_id === report_id && compliances.weekday === i) {
										compliance.status = true;
									}
								});
							}
							compliances.push(compliance);
						}

						resolve(compliances);
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getWeekdayCompliance(report_id: number, weekday: number, compliance_id: number): Promise<ReportsCompliance> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getWeekdayComplianceWhenOnline(report_id, weekday, compliance_id));
			} else {
				resolve(this.getWeekdayComplianceWhenOffline(report_id, compliance_id));
			}
		});
	}

	getWeekdayComplianceWhenOffline(report_id: number, compliance_id: number): Promise<ReportsCompliance> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsCompliances, compliance_id).then((reportComplianceDB: ReportsCompliance) => {
						if (typeof reportComplianceDB !== 'undefined') {
							resolve(reportComplianceDB);
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getWeekdayComplianceWhenOnline(report_id: number, weekday: number, compliance_id: number): Promise<ReportsCompliance> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/compliances/' + weekday + '/' + compliance_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsCompliance>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getWeekdayCompliances(report_id: number, weekday: number): Promise<ReportsCompliance[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getWeekdayCompliancesWhenOnline(report_id, weekday));
			} else {
				resolve(this.getWeekdayCompliancesWhenOffline(report_id, weekday));
			}
		});
	}

	getWeekdayCompliancesWhenOffline(report_id: number, weekday: number): Promise<ReportsCompliance[]> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsCompliances).then((reportComplianceDB: ReportsCompliance[]) => {
						let reportCompliances = [];
						if (typeof reportComplianceDB !== 'undefined') {
							reportComplianceDB.forEach(compliance => {
								if (compliance.report_id === report_id && compliance.weekday === weekday) {
									reportCompliances.push(compliance);
								}
							});

							resolve(reportCompliances);
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getWeekdayCompliancesWhenOnline(report_id: number, weekday: number): Promise<ReportsCompliance[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/compliances/' + weekday).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsCompliance[]>data.data);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	createCompliance(report_id: number, weekday: number, formData): Promise<boolean> {
		if (this.apiService.isOnline) {
			return this.apiService.post('reports/' + report_id + '/compliances/' + weekday, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Compliance saved.');
					if (typeof data.data !== 'undefined') {

						return this.indexedDBService.database.add(this.indexedDBService.tableReportsCompliances, <ReportsCompliance>data.data).then(() => {
							return true;
						}, error => {
							new Error(error);
							return true;
						});
					} else {
						return true;
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
					return false;
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
					return false;
				}
			});
		} else {
			formData['id'] = moment().valueOf();
			formData['report_id'] = report_id;
			formData['weekday'] = weekday;
			formData['created'] = moment().valueOf();
			delete formData['query'];
			return this.indexedDBService.database.add(this.indexedDBService.tableReportsCompliances, formData).then(() => {
				this.snackbarService.success('Compliance saved.');

				formData['indexedDBMethod'] = '1-create';
				return this.indexedDBService.database.add(this.indexedDBService.tableReportsCompliancesPush, formData).then(() => {
					return true;
				}, error => {
					new Error(error);
					return false;
				});
			}, error => {
				new Error(error);
				return false;
			});
		}
	}

	updateCompliance(report_id: number, weekday: number, daily_id: number, formData): Promise<boolean> {
		if (this.apiService.isOnline) {
			return this.apiService.post('reports/' + report_id + '/compliances/' + weekday + '/' + daily_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Compliance saved.');
					if (typeof data.data !== 'undefined') {
						return this.indexedDBService.database.update(this.indexedDBService.tableReportsCompliances, <ReportsCompliance>data.data).then(() => {
							return true;
						}, error => {
							new Error(error);
							return false;
						});
					} else {
						return true;
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
					return false;
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
					return false;
				}
			});
		} else {
			return this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsCompliances, 'id', daily_id).then(compliance => {
				delete formData['query'];
				let newCompliance = {...compliance, ...formData};
				newCompliance['updated'] = moment().valueOf();
				return this.indexedDBService.database.update(this.indexedDBService.tableReportsCompliances, newCompliance).then(() => {
					this.snackbarService.success('Compliance saved.');

					newCompliance['indexedDBMethod'] = '2-update';
					return this.indexedDBService.database.add(this.indexedDBService.tableReportsCompliancesPush, newCompliance).then(() => {
						return true;
					}, error => {
						new Error(error);
						return false;
					});
				}, error => {
					new Error(error);
					return false;
				});
			}, error => {
				new Error(error);
				return false;
			});
		}
	}

	deleteCompliance(report_id: number, weekday: number, daily_id: number): Promise<boolean> {
		return new Promise(resolve => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Are you sure you want to delete this compliance?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_id + '/compliances/' + weekday + '/' + daily_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Compliance deleted.');
								this.indexedDBService.database.delete(this.indexedDBService.tableReportsCompliances, daily_id).then(() => {
									resolve(true);
								}, error => {
									resolve(true);
									throw new Error(error);
								});
							} else {
								this.snackbarService.error('Error occurred while deleting compliance.');
								resolve(false);
							}
						}, error => {
							resolve(undefined);
							throw new Error(error);
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsCompliances, daily_id).then(() => {
							this.snackbarService.success('Compliance deleted.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsCompliancesPush, {
								id: daily_id,
								report_id: report_id,
								weekday: weekday,
								indexedDBMethod: '3-delete'
							}).then(() => {
								resolve(true);
							}, error => {
								resolve(undefined);
								throw new Error(error);
							});
						}, error => {
							resolve(undefined);
							throw new Error(error);
						});
					}
				}
			});
		});
	}


	getPalletReport(report_id: number, pallet_report_id: number): Promise<ReportsIkeaPalletReport> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getPalletReportWhenOnline(report_id, pallet_report_id));
			} else {
				resolve(this.getPalletReportWhenOffline(report_id, pallet_report_id));
			}
		});
	}

	getPalletReportWhenOffline(report_id: number, pallet_report_id: number): Promise<ReportsIkeaPalletReport> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then((palletReportDB: ReportsIkeaPalletReport) => {
						if (typeof palletReportDB !== 'undefined') {
							resolve(palletReportDB);
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getPalletReportWhenOnline(report_id: number, pallet_report_id: number): Promise<ReportsIkeaPalletReport> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/pallet-reports/' + pallet_report_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsIkeaPalletReport>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getPalletReports(report_id: number): Promise<ReportsIkeaPalletReport[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getPalletReportsWhenOnline(report_id));
			} else {
				resolve(this.getPalletReportsWhenOffline(report_id));
			}
		});
	}

	getPalletReportsWhenOffline(report_id: number): Promise<ReportsIkeaPalletReport[]> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsIkeaPalletReports).then((palletReportsDB: ReportsIkeaPalletReport[]) => {
						let palletReports = [];
						if (typeof palletReportsDB !== 'undefined') {
							palletReportsDB.forEach(palletReport => {
								if (palletReport.report_id === report_id) {
									palletReports.push(palletReport);
								}
							});

							resolve(palletReports);
						} else {
							resolve(undefined);
						}
					}, error => {
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getPalletReportsWhenOnline(report_id: number): Promise<ReportsIkeaPalletReport[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_id + '/pallet-reports/').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsIkeaPalletReport[]>data.data);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	createPalletReport(report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/pallet-reports', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Pallet report saved.');
					if (typeof data.data !== 'undefined') {

						this.indexedDBService.database.add(this.indexedDBService.tableReportsIkeaPalletReports, <ReportsIkeaPalletReport>data.data).then(() => {
							this.router.navigate(['/reports/' + report_id + '/view']).then(() => {});
						}, error => new Error(error));
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			formData['id'] = moment().valueOf();
			formData['report_id'] = report_id;
			formData['created'] = moment().valueOf();
			delete formData['query'];
			this.indexedDBService.database.add(this.indexedDBService.tableReportsIkeaPalletReports, formData).then(() => {
				this.snackbarService.success('Pallet report saved.');

				formData['indexedDBMethod'] = '1-create';
				this.indexedDBService.database.add(this.indexedDBService.tableReportsIkeaPalletReportsPush, formData).then(() => {
					this.router.navigate(['/reports/' + report_id + '/view']).then(() => {});
				}, error => new Error(error));
			}, error => new Error(error));
		}
	}

	updatePalletReport(report_id: number, pallet_report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_id + '/pallet-reports/' + pallet_report_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Pallet report saved.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReportsIkeaPalletReports, <ReportsIkeaPalletReport>data.data).then(() => {
							this.router.navigate(['/reports/' + report_id + '/view']).then(() => {});
						}, error => new Error(error));
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then(pallet_report => {
				delete formData['query'];
				let newPalletReport = {...pallet_report, ...formData};
				newPalletReport['updated'] = moment().valueOf();
				this.indexedDBService.database.update(this.indexedDBService.tableReportsIkeaPalletReports, newPalletReport).then(() => {
					this.snackbarService.success('Pallet report saved.');

					newPalletReport['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsIkeaPalletReportsPush, newPalletReport).then(() => {
						this.router.navigate(['/reports/' + report_id + '/view']).then(() => {});
					}, error => new Error(error));
				}, error => new Error(error));
			}, error => new Error(error));
		}
	}

	deletePalletReport(report_id: number, pallet_report_id: number): Promise<boolean> {
		return new Promise(resolve => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Are you sure you want to delete this pallet report?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_id + '/pallet-reports/' + pallet_report_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Pallet report deleted.');
								this.indexedDBService.database.delete(this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then(() => {
									resolve(true);
								}, error => {
									resolve(true);
									throw new Error(error);
								});
							} else {
								this.snackbarService.error('Error occurred while deleting pallet report.');
								resolve(false);
							}
						}, error => {
							resolve(undefined);
							throw new Error(error);
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then(() => {
							this.snackbarService.success('Pallet report deleted.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsIkeaPalletReportsPush, {
								id: pallet_report_id,
								report_id: report_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
								resolve(true);
							}, error => {
								resolve(undefined);
								throw new Error(error);
							});
						}, error => {
							resolve(undefined);
							throw new Error(error);
						});
					}
				}
			});
		});
	}


	downloadFile(url: string, filename: string) {
		if (this.apiService.isOnline) {
			if (typeof url !== 'undefined') {
				this.apiService.download(url, filename);
			} else {
				this.snackbarService.error('Invalid link, cannot be downloaded.');
			}
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}

	syncCategories(): Promise<ReportsShortcomingsCategories[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/sync/elements/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/sync/elements', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsCategories, <ReportsShortcomingsCategories[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsCategories,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsShortcomingsCategories[]>data.data))
															.catch(error => new Error(error));
													})
													.catch(error => new Error(error));
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => new Error(error));
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncSubcategories(): Promise<ReportsShortcomingsSubcategories[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/sync/elements/subelements/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/sync/elements/subelements', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsSubcategories, <ReportsShortcomingsSubcategories[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsSubcategories,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsShortcomingsSubcategories[]>data.data))
															.catch(error => new Error(error));
													})
													.catch(error => new Error(error));
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => new Error(error));
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncTypes() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/sync/elements/subelements/types/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/sync/elements/subelements/types', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												let filtered = [];
												data.data.forEach(type => {
													type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
													filtered.push(type);
												});
												if (filtered.length) {
													return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsTypes, <ReportsShortcomingsTypes[]>filtered)
														.then(() => {
															return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
																name: this.indexedDBService.tableReportsTypes,
																updated: server_date.data.updated
															})
																.then(() => resolve(<ReportsShortcomingsTypes[]>data.data))
																.catch(error => new Error(error));
														})
														.catch(error => new Error(error));
												} else {
													resolve(undefined);
												}
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => new Error(error));
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncActions() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/sync/elements/subelements/actions/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/sync/elements/subelements/actions', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												let filtered = [];
												data.data.forEach(action => {
													action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
													filtered.push(action);
												});
												if (filtered.length) {
													return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsActions, <ReportsShortcomingsActions[]>filtered)
														.then(() => {
															return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
																name: 'reports_warehouse_actions',
																updated: server_date.data.updated
															})
																.then(() => resolve(<ReportsShortcomingsActions[]>filtered))
																.catch(error => new Error(error));
														})
														.catch(error => new Error(error));
												} else {
													resolve(undefined);
												}
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => new Error(error));
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncPalletOptions(): Promise<ReportsIkeaPalletOption[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/sync/pallet-options/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/sync/pallet-options', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk('reports_ikea_pallet_optitons', <ReportsIkeaPalletOption[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsIkeaPalletOptions,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsIkeaPalletOption[]>data.data))
															.catch(error => new Error(error));
													})
													.catch(error => new Error(error));
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => new Error(error));
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	cleanupAfterReportDeletion(reportsIndexes: Array<number>) {
		reportsIndexes.forEach(report_id => {
			this.deleteReportAttachments(report_id).then(() => {
			});
			this.deleteReportBuildings(report_id).then(() => {
			});
			this.deleteReportBuildingsReports(report_id).then(() => {
			});
			this.deleteReportComments(report_id).then(() => {
			});

			this.indexedDBService.database.openCursorWithIndex(this.indexedDBService.tableReportsSendToClient, 'report_id', event => {
				let cursor = event.target.result;
				if (cursor) {
					if (cursor.value.report_id === report_id) {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsSendToClient, cursor.value.id).then(() => {
						}, error => new Error(error));
					}
				}
			}).then(() => {
			}, error => new Error(error));
		});
	}

	deleteReportAttachments(report_id: number): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachments)
				.then(reports_attachments => {
					let filtered = [];
					reports_attachments.forEach(report_attachment => {
						if (report_attachment.report_id === report_id) {
							filtered.push(report_attachment.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsAttachments, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
					return true;
				})
				.catch(error => new Error(error))
			);

			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachmentsPush)
				.then(reports_attachments => {
					let filtered = [];
					reports_attachments.forEach(report_attachment => {
						if (report_attachment.report_id === report_id) {
							filtered.push(report_attachment.entryId);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsAttachmentsPush, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
					return true;
				})
				.catch(error => new Error(error))
			);

			return Promise.all(promises).then(() => resolve());
		});
	}

	deleteReportBuildings(report_id: number): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings)
				.then(report_buildings => {
					let filtered = [];
					report_buildings.forEach(report_building => {
						if (report_building.report_id === report_id) {
							filtered.push(report_building.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildings, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
					return true;
				})
				.catch(error => new Error(error))
			);
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsPush)
				.then(report_buildings => {
					let filtered = [];
					report_buildings.forEach(report_building => {
						if (report_building.report_id === report_id) {
							filtered.push(report_building.entryId);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsPush, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
					return true;
				})
				.catch(error => new Error(error))
			);

			return Promise.all(promises).then(() => resolve());
		});
	}

	deleteReportBuildingsReports(report_id: number, building_id: number = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports)
				.then(reports_buildings_reports => {
					let filtered = [];
					reports_buildings_reports.forEach(report_building_report => {
						if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
							filtered.push(report_building_report.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsReports, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
				})
				.catch(error => new Error(error))
			);

			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReportsPush)
				.then(reports_buildings_reports => {
					let filtered = [];
					reports_buildings_reports.forEach(report_building_report => {
						if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
							filtered.push(report_building_report.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsReportsPush, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
				})
				.catch(error => new Error(error))
			);

			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReportsPhotos)
				.then(reports_buildings_reports_photos => {
					let filtered = [];
					reports_buildings_reports_photos.forEach(report_building_report_photo => {
						if (building_id === null && report_building_report_photo.report_id === report_id || building_id !== null && report_building_report_photo.id === building_id && report_building_report_photo.report_id === report_id) {
							filtered.push(report_building_report_photo.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsReportsPhotos, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
					return true;
				})
				.catch(error => new Error(error))
			);

			return Promise.all(promises).then(() => resolve());
		});
	}

	deleteReportComments(report_id: number): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsComments)
				.then(reports_comments => {
					let filtered = [];
					reports_comments.forEach(report_comment => {
						if (report_comment.report_id === report_id) {
							filtered.push(report_comment.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsComments, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
					return true;
				})
				.catch(error => new Error(error))
			);
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsCommentsPush)
				.then(reports_comments => {
					let filtered = [];
					reports_comments.forEach(report_comment => {
						if (report_comment.report_id === report_id) {
							filtered.push(report_comment.entryId);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsCommentsPush, filtered)
							.then(() => true)
							.catch(error => new Error(error));
					}
					return true;
				})
				.catch(error => new Error(error))
			);

			return Promise.all(promises).then(() => resolve());
		});
	}
}
