import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {LoadingOverlayService} from './loading-overlay.service';
import {ApiService} from '../_interceptors/api.service';
import {SnackbarService} from './snackbar.service';
import {ServerResponse} from '../_interfaces/server.response';
import {PreviousUrlService} from './previous-url.service';
import {Reports} from '../_interfaces/reports/reports';
import {ReportsComments} from '../_interfaces/reports/reports-comments';
import {ReportsBuildings} from '../_interfaces/reports/reports-buildings';
import {IndexedDBService} from './indexeddb.service';
import {ReportsAttachments} from '../_interfaces/reports/reports-attachments';
import * as moment from 'moment';
import {AuthenticationService} from './authentication.service';
import {ReportsBuildingsReports} from '../_interfaces/reports/reports-buildings-reports';
import {LocalStorageService} from './local-storage.service';
import {environment} from '../../environments/environment';
import {ReportsBuildingsMapsMarker} from '../_interfaces/reports/reports-buildings-maps-marker';
import {BuildingMapWidgetComponent} from '../core/building-map-widget/building-map-widget.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ClientsBuildingMaps} from '../_interfaces/clients-building-maps';

@Injectable()

export class KVVMReportsService {

	public errors: Subject<any> = new BehaviorSubject(null);

	private debug: boolean = !environment.production;

	constructor(private apiService: ApiService,
				private dialogService: MatDialog,
				private indexedDBService: IndexedDBService,
				private overlayService: LoadingOverlayService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private authenticationService: AuthenticationService,
				private localStorageService: LocalStorageService,
				private router: Router) {
	}

	getReports(formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getReportsWhenOnline(formData));
			} else {
				resolve(this.getReportsWhenOffline(formData));
			}
		});
	}

	getReportsWhenOffline(formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let results: Reports[] = [],
						collection: Reports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableKvvmReports).then((reportsDB: Reports[]) => {
						if (typeof reportsDB !== 'undefined') {
							collection = reportsDB;
							collection.sort((a, b) => a.created > b.created ? -1 : 1);

							if (searchString !== null) {
								collection = collection.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								results = collection.slice(startIndex, endIndex);
							} else {
								results = collection.slice();
							}
							resolve({
								recordsTotal: collection.length,
								data: results
							});
						} else {
							resolve(undefined);
						}
					}, (error) => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getReportsWhenOnline(formData = null): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getReport(report_id: number): Promise<Reports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getReportWhenOnline(report_id));
			} else {
				resolve(this.getReportWhenOffline(report_id));
			}
		});
	}

	getReportWhenOffline(report_id: number): Promise<Reports> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableKvvmReports, report_id).then((report: Reports) => {
						if (typeof report !== 'undefined') {
							resolve(report);
						} else {
							resolve(undefined);
						}
					}, (error) => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getReportWhenOnline(report_id: number): Promise<Reports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports/' + report_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<Reports>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getAttachments(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getAttachmentsWhenOnline(report_id, formData));
			} else {
				resolve(this.getAttachmentsWhenOffline(report_id, formData));
			}
		});
	}

	getAttachmentsWhenOffline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsAttachments[] = [],
						collectionData: ReportsAttachments[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableKvvmReportsAttachments).then((attachmentsDB: ReportsAttachments[]) => {
						if (typeof attachmentsDB !== 'undefined') {
							collectionData = attachmentsDB.filter(attachments => attachments.report_id === report_id);
							collectionData.sort((a, b) => a.created > b.created ? -1 : 1);

							if (searchString !== null) {
								collectionData = collectionData.filter(attachments => attachments.title.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getAttachmentsWhenOnline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports/' + report_id + '/attachments', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getComments(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getCommentsWhenOnline(report_id, formData));
			} else {
				resolve(this.getCommentsWhenOffline(report_id, formData));
			}
		});
	}

	getCommentsWhenOffline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsComments[] = [],
						collectionData: ReportsComments[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableKvvmReportsComments).then((commentsDB: ReportsComments[]) => {
						if (typeof commentsDB !== 'undefined') {
							collectionData = commentsDB.filter(comments => comments.report_id === report_id);
							collectionData.sort((a, b) => a.created > b.created ? 1 : -1);

							if (searchString !== null) {
								collectionData = collectionData.filter(comments => comments.inspector.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getCommentsWhenOnline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports/' + report_id + '/comments', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getBuildings(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingsWhenOnline(report_id, formData));
			} else {
				resolve(this.getBuildingsWhenOffline(report_id, formData));
			}
		});
	}

	getBuildingsWhenOffline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildings[] = [],
						collectionData: ReportsBuildings[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[3][search][value]'] !== 'undefined') {
							if (formData['columns[3][search][value]'] !== null && formData['columns[3][search][value]'] !== '') {
								searchString = formData['columns[3][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableKvvmReportsBuildings).then((reportBuildingsDB: ReportsBuildings[]) => {
						if (typeof reportBuildingsDB !== 'undefined') {
							collectionData = reportBuildingsDB.filter(collection2 => collection2.report_id === report_id);
							collectionData.sort((a, b) => a.sequence > b.sequence ? 1 : -1);

							if (searchString !== null) {
								collectionData = collectionData.filter(collection2 => collection2.name.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingsWhenOnline(report_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports/' + report_id + '/buildings', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getBuilding(report_id: number, formData): Promise<ReportsBuildings> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingWhenOnline(report_id, formData));
			} else {
				resolve(this.getBuildingWhenOffline(report_id, formData));
			}
		});
	}

	getBuildingWhenOffline(report_id: number, building_id: number): Promise<ReportsBuildings> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableKvvmReportsBuildings, building_id).then((reportBuildingDB: ReportsBuildings) => {
						if (typeof reportBuildingDB !== 'undefined') {
							resolve(reportBuildingDB);
						} else {
							resolve(undefined);
						}
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingWhenOnline(report_id: number, building_id: number): Promise<ReportsBuildings> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports/' + report_id + '/buildings/' + building_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsBuildings>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getBuildingReports(report_id: number, building_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingReportsWhenOnline(report_id, building_id, formData));
			} else {
				resolve(this.getBuildingReportsWhenOffline(report_id, building_id, formData));
			}
		});
	}

	getBuildingReportsWhenOffline(report_id: number, building_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildingsReports[] = [],
						collectionData: ReportsBuildingsReports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							endIndex += startIndex;
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableKvvmReportsBuildingsReports).then((buildingReportsDB: ReportsBuildingsReports[]) => {
						if (typeof buildingReportsDB !== 'undefined') {
							collectionData = buildingReportsDB.filter(collection2 => collection2.report_id === report_id && collection2.building_id === building_id);
							collectionData.sort((a, b) => a.sequence > b.sequence ? 1 : -1);

							if (searchString !== null) {
								collectionData = collectionData.filter(collection2 => collection2.location_nr.toLowerCase().indexOf(searchString) !== -1);
							}

							if (endIndex !== -1) {
								resultsData = collectionData.slice(startIndex, endIndex);
							} else {
								resultsData = collectionData.slice();
							}

							resolve({
								recordsTotal: collectionData.length,
								data: resultsData
							});
						} else {
							resolve(undefined);
						}
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingReportsWhenOnline(report_id: number, building_id: number, formData): Promise<ServerResponse> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ServerResponse>data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getBuildingReport(report_id: number, building_id: number, building_report_id): Promise<ReportsBuildingsReports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				resolve(this.getBuildingReportWhenOnline(report_id, building_id, building_report_id));
			} else {
				resolve(this.getBuildingReportWhenOffline(report_id, building_id, building_report_id));
			}
		});
	}

	getBuildingReportWhenOffline(report_id: number, building_id: number, building_report_id: number): Promise<ReportsBuildingsReports> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableKvvmReportsBuildingsReports, building_report_id).then((buildingReportDB: ReportsBuildingsReports) => {
						if (typeof buildingReportDB !== 'undefined') {
							resolve(buildingReportDB);
						} else {
							resolve(undefined);
						}
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						resolve(undefined);
						throw new Error(error);
					});
				}
			});
		});
	}

	getBuildingReportWhenOnline(report_id: number, building_id: number, building_report_id: number): Promise<ReportsBuildingsReports> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data[0] !== 'undefined') {
										resolve(<ReportsBuildingsReports>data.data[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					resolve(undefined);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	updateBuildingReport(report_id: number, building_id: number, building_report_id: number, formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Building report updated!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableKvvmReportsBuildingsReports, <ReportsBuildingsReports>data.data).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}

					this.router.navigate(['/kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('An error occurred while loading the data, please try again.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableKvvmReportsBuildingsReports, 'id', building_report_id).then(report => {
				report['is_resolved'] = true;
				report['client_fixed_status'] = true;
				report['client_fixed_date'] = moment().valueOf();
				report['updated'] = moment().valueOf();

				let newReport = {
					report_id: report_id,
					building_id: building_id,
					building_report_id: building_report_id,
					client_fixed_status: true,
					is_resolved: true,
					client_fixed_date: moment().valueOf()
				};

				this.indexedDBService.database.update(this.indexedDBService.tableKvvmReportsBuildingsReports, report).then(() => {
					this.snackbarService.success('Building report updated!');

					this.indexedDBService.database.add(this.indexedDBService.tableKvvmReportsBuildingsReportsSolved, newReport).then(() => {
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});

					this.router.navigate(['/kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		}
	}

	downloadFile(url: string, filename: string) {
		if (this.apiService.isOnline) {
			if (typeof url !== 'undefined') {
				this.apiService.download(url, filename);
			} else {
				this.snackbarService.error('Invalid link, cannot be downloaded.');
			}
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}

	getBuildingMap(building_map_id: number): Promise<ClientsBuildingMaps> {
		return new Promise(resolve => {
			this.apiService.get('kvvm-reports/building-map/' + building_map_id).then((data: ServerResponse) => {
				if (typeof data.success !== 'undefined') {
					if (data.success === true) {
						if (typeof data.data !== 'undefined') {
							if (typeof data.data[0] !== 'undefined') {
								resolve(<ClientsBuildingMaps>data.data[0]);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				} else {
					resolve(undefined);
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	getBuildingMapMarkers(report_id: number, building_id: number, shortcoming_id: number = null): Promise<ReportsBuildingsMapsMarker[]> {
		return new Promise(resolve => {
			this.apiService.get('kvvm-reports/' + report_id + '/buildings/' + building_id + '/maps' + (shortcoming_id !== null ? '/' + shortcoming_id : ''), {
				start: 0,
				length: -1
			}).then((data: ServerResponse) => {
				if (typeof data.success !== 'undefined') {
					if (data.success === true) {
						if (typeof data.data !== 'undefined') {
							return resolve(<ReportsBuildingsMapsMarker[]>data.data);
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				} else {
					resolve(undefined);
				}
			}).catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	showReportBuildingMap(building_map_url, readonly = true, markers: ReportsBuildingsMapsMarker[] = []) {
		return new Promise(resolve => {
			let dialogRef: MatDialogRef<BuildingMapWidgetComponent>;
			dialogRef = this.dialogService.open(BuildingMapWidgetComponent, {
				data: {
					readonly: readonly,
					buildingMapUrl: building_map_url,
					markers: markers,
				}
			});
			dialogRef.afterClosed().subscribe(() => {
				resolve(dialogRef.componentInstance.markers);
			});
		});

	}
}
