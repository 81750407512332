import {Component, OnDestroy, OnInit, Renderer2, RendererFactory2, ViewChild} from '@angular/core';
import {ApiService} from '../../_interceptors/api.service';
import {ServerResponse} from '../../_interfaces/server.response';
import {AuthenticationService} from '../../_services/authentication.service';
import {Subscription} from 'rxjs/Subscription';
import {LocalStorageService} from '../../_services/local-storage.service';
import {User} from '../../_interfaces/user';
import {DomSanitizer} from '@angular/platform-browser';
import {IndexedDBService} from '../../_services/indexeddb.service';
import {Reports} from '../../_interfaces/reports/reports';
import {version} from '../../../environments/version';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

	@ViewChild('image', {static: false}) image;

	public client_logo: any = null;
	public company_name: string = null;
	public reports: number = 0;
	public local_report_counting: boolean = false;
	public kvvm_reports: number = 0;

	private renderer: Renderer2;
	public version = version;

	private _authenticationSubscription: Subscription = null;

	constructor(public apiService: ApiService,
				private rendererFactory: RendererFactory2,
				private sanitizer: DomSanitizer,
				private localStorageService: LocalStorageService,
				private authenticationService: AuthenticationService,
				private indexedDBService: IndexedDBService) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	ngOnInit(): void {
		if (this.apiService.isOnline) {
			this.local_report_counting = false;
			this.apiService.get('reports/count', {'ngsw-bypass': true}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							if (typeof data.data !== 'undefined') {
								if (typeof data.data.reports !== 'undefined') {
									this.reports = data.data.reports;
								}
								if (typeof data.data.kvvm_reports !== 'undefined') {
									this.kvvm_reports = data.data.kvvm_reports;
								}
								if (typeof data.data === 'number') {
									this.reports = data.data;
								}
							}
						}
					}
				}
			}).catch(error => {
				throw new Error(error);
			});
		} else {
			this.local_report_counting = true;
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReports).then((reports: Reports[]) => {
						if (typeof reports !== 'undefined') {
							this.reports = reports.length;
						}
					});
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableKvvmReports).then((reports: Reports[]) => {
						if (typeof reports !== 'undefined') {
							this.kvvm_reports = reports.length;
						}
					});
				}
			});
		}

		this._authenticationSubscription = this.authenticationService.user.subscribe(user => {
			if (user) {
				this.company_name = user.company_name;
				if (this.apiService.isOnline) {
					this.apiService.get('accounts/my-account')
						.then((data: ServerResponse) => {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									this.localStorageService.set('currentUser', <User>data.data);
									if (data.data.client_logo !== null && data.data.client_logo !== '') {
										this.client_logo = data.data.client_logo + '?ngsw-bypass=true';

										if (this.client_logo !== user.client_logo) {
											setTimeout(() => {
												if (typeof this.image !== 'undefined') {
													if (typeof this.image.nativeElement !== 'undefined') {
														let cv: HTMLCanvasElement = this.renderer.createElement('canvas'),
															ctx: CanvasRenderingContext2D = cv.getContext('2d', {alpha: false});

														this.image.nativeElement.onload = () => {
															let image = this.image.nativeElement;
															cv.width = image.naturalWidth;
															cv.height = image.naturalHeight;

															ctx.drawImage(image, 0, 0, cv.width, cv.height);
															this.localStorageService.set('logo', cv.toDataURL('image/jpeg', 0.8));
														};
													} else {
														this.client_logo = null;
													}
												} else {
													this.client_logo = null;
												}
											}, 1000);
										} else {
											this.client_logo = null;
										}
									} else {
										this.client_logo = null;
									}
								} else {
									this.client_logo = null;
								}
							} else {
								this.client_logo = null;
							}
						})
						.catch(error => console.log(error));
				} else {
					let logo = this.localStorageService.get('logo');
					if (logo !== null) {
						this.client_logo = logo;
					}
				}
			} else {
				this.client_logo = null;
			}
		});
	}

	ngOnDestroy(): void {
		if (this._authenticationSubscription !== null) {
			this._authenticationSubscription.unsubscribe();
		}
	}
}
