import {Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, HostBinding, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {routeAnimation} from '../../../../../route.animation';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GeneralService} from '../../../../../_services/general.service';
import {FormsService} from '../../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../../_services/previous-url.service';
import {ReportsService} from '../../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ReportsShortcomingsCategories} from '../../../../../_interfaces/reports/reports-shortcomings-categories';
import {ReportsShortcomingsSubcategories} from '../../../../../_interfaces/reports/reports-shortcomings-subcategories';
import {ReportsShortcomingsTypes} from '../../../../../_interfaces/reports/reports-shortcomings-types';
import {ReportsShortcomingsActions} from '../../../../../_interfaces/reports/reports-shortcomings-actions';
import {PageTitleService} from '../../../../../_services/page-title.service';
import {ApiService} from '../../../../../_interceptors/api.service';
import {ServerResponse} from '../../../../../_interfaces/server.response';
import {Reports} from '../../../../../_interfaces/reports/reports';
import {environment} from '../../../../../../environments/environment';
import {ReportsBuildings} from '../../../../../_interfaces/reports/reports-buildings';
import {RemovablePhotoComponent} from '../../../../../core/removable-photo/removable-photo.component';
import {FileInputComponent} from 'ngx-material-file-input';

@Component({
	selector: 'app-reports-buildings-reports-create',
	templateUrl: './reports-buildings-reports-create.component.html',
	styleUrls: ['./reports-buildings-reports-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsBuildingsReportsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public building_id: number = null;
	public missingData: Array<string> = [];

	public category_id: number = null;
	public subcategory_id: number = null;
	public categories: ReportsShortcomingsCategories[] = [];
	public subcategories: ReportsShortcomingsSubcategories[] = [];
	public actions: ReportsShortcomingsActions[] = [];
	public types: ReportsShortcomingsTypes[] = [];

	@ViewChild('photos', {read: ViewContainerRef, static: false}) photos: ViewContainerRef = null;
	@ViewChild('photosContainer', {static: false}) photosContainer: FileInputComponent;
	public photosRef: Array<ComponentRef<RemovablePhotoComponent>> = [];
	public photosCount: number = 0;

	public reportsBuildingsReportCreateForm = this.formBuilder.group({
		location_nr: ['', Validators.required],
		lib_warehouse_element_id: ['', Validators.required],
		lib_warehouse_subelement_id: ['', Validators.required],
		color_code: ['0', Validators.required],
		measure_depth: [1, Validators.required],
		measure_height: [1, Validators.required],
		description: [''],
		warehouse_type_ids: [''],
		warehouse_type_field_value: [''],
		warehouse_action_ids: [''],
		category_name: [''],
		subcategory_name: [''],
		'photosContainer[]': [''],
		photos: this.formBuilder.array([])
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(private generalService: GeneralService,
				private apiService: ApiService,
				private resolver: ComponentFactoryResolver,
				public reportsService: ReportsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportsBuildingsReportCreateForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);

			this.reportsService.getReport(this.report_id).then((report: Reports) => {
				if (typeof report !== 'undefined') {

					this.reportsService.getBuilding(this.report_id, this.building_id).then((building: ReportsBuildings) => {
						this.pageTitleService.setTitle(building.name + ' | New building inspection | ' + environment.webappName);
						this.pageTitleService.setPageTitle(building.name);
					});
				}
			});

			this.loadData();
		});

		this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_id + '/buildings/' + this.building_id + '/reports');

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		this.clearAddedPhotos();
	}

	getSubcategories(category_id: number) {
		this.category_id = category_id;
		this.actions = [];
		this.types = [];
		this.reportsService.getShortcomingSubcategories(category_id).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					this.subcategories = <ReportsShortcomingsSubcategories[]>data.data;
				}
			}
		});
	}

	getDetails(subcategory_id: number) {
		this.subcategory_id = subcategory_id;

		this.reportsService.getShortcomingTypes(this.category_id, this.subcategory_id).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					this.types = <ReportsShortcomingsTypes[]>data.data;

					const form = this.reportsBuildingsReportCreateForm;
					form.removeControl('warehouse_type_ids');
					form.removeControl('warehouse_type_field_value');
					form.addControl('warehouse_type_ids', new FormArray([]));
					form.addControl('warehouse_type_field_value', new FormGroup({}));

					const control = <FormArray>this.reportsBuildingsReportCreateForm.get('warehouse_type_ids'),
						control2 = <FormGroup>this.reportsBuildingsReportCreateForm.get('warehouse_type_field_value');

					data.data.forEach((data2, index) => {
						control.push(new FormControl(data2.id));

						if (data2.amounts === true) {
							control2.addControl(data2.id, new FormControl(''));
						}

						control.get(index + '').setValue(false);
					});
				} else {
					this.missingData.push('Shortcoming types');
				}
			} else {
				this.missingData.push('Shortcoming types');
			}

		});

		this.reportsService.getShortcomingActions(this.category_id, this.subcategory_id).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					this.actions = <ReportsShortcomingsActions[]>data.data;
					const form = this.reportsBuildingsReportCreateForm;
					form.removeControl('warehouse_action_ids');
					form.addControl('warehouse_action_ids', new FormArray([]));

					const control = <FormArray>this.reportsBuildingsReportCreateForm.get('warehouse_action_ids');
					data.data.forEach((data2, index) => {
						control.push(new FormControl(data2.id));

						control.get(index + '').setValue(false);
					});
				} else {
					this.missingData.push('Actions');
				}
			} else {
				this.missingData.push('Actions');
			}
		});
	}

	fixAngularScrewUpAndInsertActualValue(control_name, event, index) {
		if (event.checked) {
			this.reportsBuildingsReportCreateForm.controls[control_name]['controls'][index].setValue(parseInt(event.source.value, 10));
		}
	}

	decreaseValue(element, toggleElement = null) {
		const el = this.reportsBuildingsReportCreateForm.get(element),
			val = parseInt(el.value, 10);

		el.setValue(val > 1 ? val - 1 : '');
		if (val === 1 && toggleElement !== null) {
			let te = toggleElement.split('.');
			this.reportsBuildingsReportCreateForm.get(te[0]).get(te[1]).setValue(false);
		}
	}

	increaseValue(element, toggleElement = null, id = null) {
		const el = this.reportsBuildingsReportCreateForm.get(element),
			val = parseInt(el.value, 10);

		el.setValue(isNaN(val) ? 1 : val + 1);
		if (toggleElement !== null) {
			let te = toggleElement.split('.');
			this.reportsBuildingsReportCreateForm.get(te[0]).get(te[1]).setValue(id);
		}
	}

	loadData() {
		this.reportsService.getShortcomingCategories().then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					this.categories = <ReportsShortcomingsCategories[]>data.data;
					data.data.forEach(category => {
						if (category.category_name === 'Pallet racking') {
							this.reportsBuildingsReportCreateForm.get('lib_warehouse_element_id').setValue(category.id);
							this.getSubcategories(category.id);
							this.setCategoryName(category.category_name);
						}
					});
				} else {
					this.missingData.push('Category');
				}
			} else {
				this.missingData.push('Category');
			}
		});
	}

	setCategoryName(category_name: string) {
		this.reportsBuildingsReportCreateForm.get('category_name').setValue(category_name);
	}

	setSubcategoryName(subcategory_name: string) {
		this.reportsBuildingsReportCreateForm.get('subcategory_name').setValue(subcategory_name);
	}

	clearAddedPhotos(): void {
		if (this.photosRef.length) {
			this.photosRef.forEach(ref => ref.destroy());
		}
	}

	detectUploadFiles(event: any): void {
		if (typeof event !== 'undefined') {
			if (typeof event.target !== 'undefined') {
				if (typeof event.target.files !== 'undefined') {
					let formArray = this.reportsBuildingsReportCreateForm.get('photos') as FormArray;

					Object.keys(event.target.files).map(file => {
						this.photosCount = formArray.controls.length + 1;

						if (this.photosCount < 11) {
							const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(RemovablePhotoComponent);
							let component: any = null;
							component = this.photos.createComponent(factory);
							component.instance.photo = event.target.files[file];
							component.instance.filename = event.target.files[file].name;
							component.instance.size = event.target.files[file].size;

							this.photosRef.push(component);

							let control = new FormControl(event.target.files[file]);
							formArray.push(control);

							component.instance.destroy.subscribe(() => {
								let index = this.photos.indexOf(component.hostView);
								formArray.removeAt(index);
								component.destroy();
								this.photosCount = formArray.controls.length;
							});
						}
					});
				}
			}
		}
	}
}
