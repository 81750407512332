import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {ReportsService} from '../../../../_services/reports.service';
import {ReportsIkeaPalletOption, ReportsIkeaPalletReport} from '../../../../_interfaces/reports/reports-ikea-pallet-report';

@Component({
	selector: 'app-reports-pallet-report-list',
	templateUrl: './reports-pallet-reports-list.component.html',
	styleUrls: ['./reports-pallet-reports-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsPalletReportsListComponent {

	@HostBinding('@routeAnimation') routerTransition = true;

	@Input('reportId') reportId: number = null;
	@Input('palletOptions') palletOptions: ReportsIkeaPalletOption[] = [];

	@Output('reportHallways') reportHallways = new EventEmitter<Array<number>>();
	private hallways: Array<number> = [];

	public palletReports: ReportsIkeaPalletReport[] = [];

	constructor(private reportsService: ReportsService) {
	}

	ngOnInit(): void {
		this.getPalletReports();
	}

	getPalletReports() {
		this.palletReports = [];
		this.hallways = [];

		this.reportsService.getPalletReports(this.reportId).then((data: ReportsIkeaPalletReport[]) => {
			if (typeof data !== 'undefined') {
				data.forEach(d => {
					let report = d;
					report['palletOptions'] = [];
					if (typeof d.pallet_option_ids !== 'undefined') {
						d.pallet_option_ids.forEach(po_id => {
							let result = this.palletOptions.find(po => po.id === po_id);

							if (typeof result !== 'undefined') {
								report['palletOptions'].push(result.option_name);
							}
						});
					}
					this.palletReports.push(report);

					if (typeof d.hallways !== 'undefined') {
						if (d.hallways !== null) {
							this.hallways.push(...d.hallways);
						}
					}
				});
				this.reportHallways.emit(this.hallways);
			}
		});
	}

	deletePalletReport(report_id: number, pallet_report_id: number) {
		this.reportsService.deletePalletReport(report_id, pallet_report_id).then(() => {
			this.getPalletReports();
		});
	}
}
