import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import * as moment from 'moment';

@Component({
	selector: 'app-reports-create',
	templateUrl: './reports-create.component.html',
	styleUrls: ['./reports-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public reportsCreateForm = this.formBuilder.group({
		type: ['', Validators.required],
		report_nr: ['', Validators.required],
		week: [''],
		client_description: ['']
	});
	public year: number = null;

	private _formSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.year = parseInt(moment().format('Y'), 10);
		this.formsService.form = this.reportsCreateForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._formSubscription = this.reportsCreateForm.get('type').valueChanges.subscribe(value => {
			let week = this.reportsCreateForm.controls['week'],
				reportNr = this.reportsCreateForm.controls['report_nr'];
			if (value == 'warehouse') {
				week.setValue('');
				week.setValidators(null);
				week.updateValueAndValidity();
				reportNr.setValidators(Validators.required);
				reportNr.updateValueAndValidity();
			} else {
				week.setValue(moment().format('W'));
				week.setValidators(Validators.required);
				week.updateValueAndValidity();
				reportNr.setValue('');
				reportNr.setValidators(null);
				reportNr.updateValueAndValidity();
			}
		});

		this.previousUrlService.setPreviousUrlIfNull('/reports');

		this.reportsCreateForm.get('type').setValue('warehouse');
	}

	ngOnDestroy(): void {
		if (this._formSubscription !== null) {
			this._formSubscription.unsubscribe();
		}
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
