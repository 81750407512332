import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {ReportsService} from '../../../_services/reports.service';
import {ServerResponse} from '../../../_interfaces/server.response';
import {ApiService} from '../../../_interceptors/api.service';

@Component({
	selector: 'app-reports-list',
	templateUrl: './reports-list.component.html',
	styleUrls: ['./reports-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsListComponent implements OnInit {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public reports: Reports[] = [];
	public warehouseReports: Reports[] = [];
	public palletReports: Reports[] = [];
	public complianceReports: Reports[] = [];

	@Input() smallWidget: boolean = false;

	constructor(public reportsService: ReportsService,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.reports.length) {
				this.currentPage = 0;
				this.getReports(false);
			}
		});

		this.currentPage = 0;
		this.getReports(false);
	}

	getReports(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getReports({
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[1][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}

					if (append_data === false) {
						this.complianceReports = [];
						this.palletReports = [];
						this.warehouseReports = [];
					}

					data.data.forEach(report => {
						if (report.type === 'compliance') {
							this.complianceReports.push(<Reports>report);
						} else if (report.type == 'ikea-pallet') {
							this.palletReports.push(<Reports>report);
						} else {
							this.warehouseReports.push(report);
						}
					});
				}
				this.isLoading = false;
			});
		}
	}

	deleteReport(report_id: number) {
		this.reportsService.deleteReport(report_id).then(status => {
			if (status === true) {
				let indexReports = this.reports.findIndex(d => d.id === report_id),
					indexWarehouseReports = this.warehouseReports.findIndex(d => d.id === report_id),
					indexPalletReports = this.palletReports.findIndex(d => d.id === report_id),
					indexComplianceReports = this.complianceReports.findIndex(d => d.id === report_id);

				if (indexReports !== -1) {
					this.reports.splice(indexReports, 1);
				}
				if (indexWarehouseReports !== -1) {
					this.warehouseReports.splice(indexWarehouseReports, 1);
				}
				if (indexPalletReports !== -1) {
					this.palletReports.splice(indexPalletReports, 1);
				}
				if (indexComplianceReports !== -1) {
					this.complianceReports.splice(indexComplianceReports, 1);
				}
			}
		});
	}

	search(event) {
		this.searchString = event;
		this.currentPage = 0;
		this.getReports(false);
	}
}
