import {Component} from '@angular/core';
import {PreviousUrlService} from '../../_services/previous-url.service';

@Component({
	selector: 'app-toolbar-bottom',
	templateUrl: './toolbar-bottom.component.html',
	styleUrls: ['./toolbar-bottom.component.scss']
})

export class ToolbarBottomComponent {

	constructor(public previousPageService: PreviousUrlService) {
	}
}
