import {Component, HostBinding, Input} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {ApiService} from '../../../../_interceptors/api.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {environment} from '../../../../../environments/environment';
import {SnackbarService} from '../../../../_services/snackbar.service';

@Component({
	selector: 'app-reports-view-warehouse',
	templateUrl: './reports-view-warehouse.component.html',
	styleUrls: ['./reports-view-warehouse.component.scss'],
	animations: [routeAnimation]
})

export class ReportsViewWarehouseComponent {

	@HostBinding('@routeAnimation') routerTransition = true;

	@Input('report') report: Reports = null;

	constructor(public apiService: ApiService,
				private snackbarService: SnackbarService) {
	}

	downloadPDFReport(report_id: number, file_type: string) {
		if (this.apiService.isOnline) {
			this.apiService.download(environment.api_endpoint + 'reports/' + report_id + '/download/' + file_type, null, null, 'application/pdf');
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}

	downloadXLSReport(report_id: number, file_type: string) {
		if (this.apiService.isOnline) {
			this.apiService.download(environment.api_endpoint + 'reports/' + report_id + '/download/' + file_type, null, null, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}
}
