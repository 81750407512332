import {Component, HostBinding, OnInit} from '@angular/core';
import {KnowledgeBaseService} from '../../../_services/knowledge-base.service';
import {KnowledgeBase} from '../../../_interfaces/knowledge-base/knowledge-base';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
	selector: 'app-knowledge-base',
	templateUrl: './knowledge-base.component.html',
	styleUrls: ['./knowledge-base.component.scss']
})

export class KnowledgeBaseComponent implements OnInit {

	public groups: KnowledgeBase[] = [];

	@HostBinding('class.public') publicClass: boolean = false;

	constructor(private knowledgeBaseService: KnowledgeBaseService,
				private pageTitleService: PageTitleService,
				private authenticationService: AuthenticationService) {
	}

	ngOnInit(): void {
		this.authenticationService.user.subscribe(user => {
			this.publicClass = !user;
		});

		this.knowledgeBaseService.getKnowledgeBase().then(data => {
			if (typeof data !== 'undefined') {
				this.groups = data;

				this.pageTitleService.setTitle('Knowledge base | ' + environment.webappName);
				this.pageTitleService.setPageTitle('Knowledge base');
			}
		});
	}
}
