import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {MatIconRegistry} from '@angular/material/icon';
import {RoutingModule} from './app-routing.module';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {JwtInterceptor} from './_interceptors/jwt.interceptor';
import {WebappModule} from './webapp/webapp.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {provideErrorHandler} from './_services/sentry-error-handler.service';
import {IndexedDBService} from './_services/indexeddb.service';
import {environment} from '../environments/environment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {Angular2FontawesomeModule} from 'angular2-fontawesome';

export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient);
}

@Injectable()

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		BrowserAnimationsModule,
		CoreModule,
		WebappModule,
		HttpClientModule,
		ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		Angular2FontawesomeModule,
	],
	providers: [
		MatIconRegistry,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{
			provide: ErrorHandler,
			useFactory: provideErrorHandler
		},
		IndexedDBService
	],
	exports: [
		TranslateModule
	],
	bootstrap: [AppComponent]
})

export class AppModule {
}
