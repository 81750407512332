import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportsService} from '../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ApiService} from '../../../../_interceptors/api.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';

@Component({
	selector: 'app-reports-buildings-create',
	templateUrl: './reports-buildings-create.component.html',
	styleUrls: ['./reports-buildings-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsBuildingsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;

	public reportsBuildingCreateForm = this.formBuilder.group({
		name: ['', [Validators.required, Validators.maxLength(50)]],
		description: [''],
		query: ['']
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private apiService: ApiService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.reportsBuildingCreateForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);

			this.reportsService.getReport(this.report_id).then((report: Reports) => {
				if (typeof report !== 'undefined') {

					this.pageTitleService.setTitle(report.report_nr + ' | Add report building | ' + environment.webappName);
					this.pageTitleService.setPageTitle(report.report_nr);
				}
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_id + '/buildings');
		});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
