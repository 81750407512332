import {transition, style, trigger, animate} from '@angular/animations';

export let routeAnimation = trigger('routeAnimation', [
	transition('void => *', [
		style({
			opacity: 0,
		}),
		animate('400ms 150ms ease-in-out', style({
			opacity: 1,
		}))
	]),
]);
