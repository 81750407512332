import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {KnowledgeBaseService} from '../../../_services/knowledge-base.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {KnowledgeBase} from '../../../_interfaces/knowledge-base/knowledge-base';
import {environment} from '../../../../environments/environment';
import {PageTitleService} from '../../../_services/page-title.service';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
	selector: 'app-knowledge-base-article',
	templateUrl: './knowledge-base-article.component.html',
	styleUrls: ['./knowledge-base-article.component.scss']
})

export class KnowledgeBaseArticleComponent implements OnInit, OnDestroy {

	public group: KnowledgeBase = null;

	@HostBinding('class.public') publicClass: boolean = false;

	private _activatedRouteSubscription: Subscription = null;

	constructor(private knowledgeBaseService: KnowledgeBaseService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService,
				private authenticationService: AuthenticationService) {
	}

	ngOnInit(): void {
		this.authenticationService.user.subscribe(user => {
			this.publicClass = !user;
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe(params => {
			this.knowledgeBaseService.getKnowledgeBaseArticle(params['group_url'], params['article_url']).then(data => {
				if (typeof data !== 'undefined') {
					this.group = data;

					this.pageTitleService.setTitle(data.title + ' | Support | ' + environment.webappName);
					this.pageTitleService.setPageTitle(data.title);
				}
			});
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}
}
