import {Component, Input} from '@angular/core';
import {PageTitleService} from '../../_services/page-title.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {ApiService} from '../../_interceptors/api.service';
import {SnackbarService} from '../../_services/snackbar.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent {

	@Input() sidenav: any;

	constructor(public authenticationService: AuthenticationService,
				public pageTitleService: PageTitleService,
				public apiService: ApiService,
				private snackbarService: SnackbarService,
				private translate: TranslateService) {
	}

	showOfflineMessage() {
		this.translate.get('errors.offline-warning').subscribe(text => this.snackbarService.warning(text, 10000));
	}
}
