import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import {ApiService} from '../../../_interceptors/api.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {SnackbarService} from '../../../_services/snackbar.service';
import {ReportsIkeaPalletOption} from '../../../_interfaces/reports/reports-ikea-pallet-report';
import {ServerResponse} from '../../../_interfaces/server.response';

@Component({
	selector: 'app-reports-view',
	templateUrl: './reports-view.component.html',
	styleUrls: ['./reports-view.component.scss'],
	animations: [routeAnimation]
})

export class ReportsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	public report: Reports = null;
	public palletOptions: ReportsIkeaPalletOption[] = [];

	private _activatedRouteSubscription: Subscription = null;

	constructor(private reportsService: ReportsService,
				public apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/reports');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getReport(this.report_id).then((data: Reports) => {
			if (typeof data !== 'undefined') {
				this.report = data;
				if (typeof data.type === 'undefined') {
					data.type = 'warehouse';
				}

				if (data.type === 'ikea-pallet') {
					this.reportsService.getPalletOptions().then((data: ServerResponse) => {
						if (typeof data.data !== 'undefined') {
							this.palletOptions = data.data;
						} else {
							this.missingData.push('Pallet Options');
						}
					});
				}

				this.pageTitleService.setTitle(data.report_nr + ' | View report | ' + environment.webappName);
				this.pageTitleService.setPageTitle(data.report_nr);

				this.dataLoaded = true;
			} else {
				this.missingData.push('Rapportage');
			}
		});
	}
}
