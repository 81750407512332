import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportsService} from '../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ReportsComments} from '../../../../_interfaces/reports/reports-comments';
import {ApiService} from '../../../../_interceptors/api.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';

@Component({
	selector: 'app-reports-comments-edit',
	templateUrl: './reports-comments-edit.component.html',
	styleUrls: ['./reports-comments-edit.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCommentsEditComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public comment_id: number = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	public reportCommentEditForm = this.formBuilder.group({
		comment: ['', Validators.required]
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private apiService: ApiService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportCommentEditForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this.comment_id = parseInt(params['comment_id'], 10);

			this.reportsService.getReport(this.report_id).then((report: Reports) => {
				if (typeof report !== 'undefined') {
					this.pageTitleService.setTitle(report.report_nr + ' | Edit report comment | ' + environment.webappName);
					this.pageTitleService.setPageTitle(report.report_nr);
				}
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_id + '/view');

			this.loadData();
		});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getComment(this.report_id, this.comment_id).then((data: ReportsComments) => {
			if (typeof data !== 'undefined') {
				this.dataLoaded = true;
				this.formsService.populateForm(data);
			} else {
				this.missingData.push('Comment');
			}
		});
	}
}
