import {Component, OnInit} from '@angular/core';
import {routeAnimation} from '../../route.animation';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-page-forbidden',
	templateUrl: './page-forbidden.component.html',
	styleUrls: ['./page-forbidden.component.scss'],
	animations: [routeAnimation]
})

export class PageForbiddenComponent implements OnInit {

	constructor(private titleService: Title,
				private translate: TranslateService) {
	}

	ngOnInit(): void {
		this.translate.get('page-titles.access-denied').subscribe(text => this.titleService.setTitle(text));
	}
}
