import {Injectable} from '@angular/core';
import {ApiService} from '../_interceptors/api.service';
import {ServerResponse} from '../_interfaces/server.response';

import {environment} from '../../environments/environment';
import {IndexedDBService} from './indexeddb.service';
import {SnackbarService} from './snackbar.service';
import {Settings} from '../_interfaces/settings';

@Injectable()

export class GeneralService {

	public itemsPerPage: number = 20;
	public itemsPerPageShort: number = 5;

	private debug: boolean = !environment.production;

	constructor(private apiService: ApiService,
				private snackbarService: SnackbarService,
				private indexedDBService: IndexedDBService) {
	}

	getSettings(): Promise<Settings[]> {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast('settings').then(
						settingsDB => {
							if (typeof settingsDB !== 'undefined') {
								if (settingsDB.length) {
									resolve(settingsDB);
								}
							}
						});

					this.syncSettings()
						.then((settings: Settings[]) => {
							if (typeof settings !== 'undefined') {
								resolve(settings);
							} else {
								resolve(undefined);
							}
						})
						.catch(error => {
							reject();
							throw new Error(error);
						});
				}
			});
		});
	}

	syncSettings(): Promise<Settings[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('settings').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									let settings = [];
									Object.keys(data.data).map(key => {
										settings.push({
											name: key,
											value: data.data[key]
										});
									});
									return this.indexedDBService.database.updateBulk('settings', settings)
										.then(() => {
											resolve(<Settings[]>settings);
										})
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					} else {
						resolve();
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}
}
