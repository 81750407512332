import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ApiService} from '../../../_interceptors/api.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {SnackbarService} from '../../../_services/snackbar.service';
import {KVVMReportsService} from '../../../_services/kvvm-reports.service';

@Component({
	selector: 'app-kvvm-reports-view',
	templateUrl: './reports-view.component.html',
	styleUrls: ['./reports-view.component.scss'],
	animations: [routeAnimation]
})

export class KVVMReportsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public report: Reports = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	private _activatedRouteSubscription: Subscription = null;

	constructor(private reportsService: KVVMReportsService,
				public apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/kvvm-reports');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getReport(this.report_id).then((data: Reports) => {
			if (typeof data !== 'undefined') {
				this.report = data;

				this.pageTitleService.setTitle(data.report_nr + ' | View report | ' + environment.webappName);
				this.pageTitleService.setPageTitle(data.report_nr);

				this.dataLoaded = true;
			} else {
				this.missingData.push('Report');
			}
		});
	}

	downloadReport(report_id: number, file_type: string) {
		if (this.apiService.isOnline) {
			this.apiService.download(environment.api_endpoint + 'kvvm-reports/' + report_id + '/download/' + file_type);
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}
}
