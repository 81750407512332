import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/empty';
import {User} from '../_interfaces/user';
import {Router} from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ApiService} from '../_interceptors/api.service';
import {ServerResponse} from '../_interfaces/server.response';
import {SnackbarService} from './snackbar.service';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {LocalStorageService} from './local-storage.service';
import {environment} from '../../environments/environment';

@Injectable()

export class AuthenticationService {

	private requestCount: number = 0;

	public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	public errors: BehaviorSubject<any> = new BehaviorSubject(null);

	public code: string = null;
	public sessionID: string = null;

	readonly user_type: string = null;

	constructor(private router: Router,
				private http: HttpClient,
				private apiService: ApiService,
				private localStorageService: LocalStorageService,
				private snackbarService: SnackbarService) {
		this.apiService.loginError.subscribe(status => {
			if (status === true) {
				this.logout();
			}
		});

		let user = <User>this.localStorageService.get('currentUser');
		if (user) {
			this.user.next(user);
			this.user_type = user.type;
		}
	}

	isAuthenticated(): Observable<boolean> {
		if (this.requestCount >= 5 && this.apiService.isOnline) {
			this.requestCount = 0;

			return this.http.get(environment.api_endpoint + 'accounts/verify-login')
				.map((data: ServerResponse) => {
					return data.success === true;
				})
				.catch((error: HttpResponse<any>) => {
					console.log(error);
					if (error.status === 401) {
						this.apiService.loginError.next(true);
					}
					return Observable.of(false);
				});
		} else {
			this.requestCount++;

			return this.user
				.map(user => {
					let ls_user = this.localStorageService.get('currentUser');

					if (user) {
						if (ls_user === null) {
							this.localStorageService.set('currentUser', user);
						}
						return true;
					} else {
						return ls_user !== null;
					}
				});
		}
	}

	// noinspection JSUnusedGlobalSymbols
	canUserAccessProperty(allowed_user_roles: Array<string>): boolean {
		if (typeof allowed_user_roles !== 'undefined') {
			return allowed_user_roles.indexOf(this.user_type) !== -1;
		} else {
			return true;
		}
	}

	login(formData, returnUrl: string = null): void {
		if (typeof formData.email !== 'undefined' && typeof formData.password !== 'undefined') {
			if (formData.email !== '' && formData.password !== '') {
				this.apiService.post('login', formData)
					.then((data: ServerResponse) => {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									let validLogin: boolean = true;
									if (typeof data.data.account_type !== 'undefined') {
										if (data.data.account_type !== 'ikea') {
											this.snackbarService.error('Invalid login attempt: you can\'t log in here!');
											this.logout(true);
											validLogin = false;
										}
									}
									if (validLogin === true) {
										if (typeof data.data.twoFactorAuthenticationRequired !== 'undefined') {
											this.localStorageService.set('sessionID', data.data.sessionID);
											this.localStorageService.set('two-factor-authentication', true);
											this.router.navigate(['/two-factor-authentication']).then(() => {
											});
										} else {
											this.localStorageService.set('currentUser', <User>data.data);

											this.snackbarService.success('You are now logged in.');
											this.apiService.loginError.next(false);
											this.user.next(<User>data.data);
											if (returnUrl !== null) {
												this.router.navigateByUrl(returnUrl).then(() => {
												});
											} else {
												this.router.navigate(['/']).then(() => {
												});
											}
										}
									}
								} else {
									if (typeof data.message !== 'undefined') {
										this.snackbarService.error(data.message);
									} else {
										this.snackbarService.error('An error occurred while loading the data, please try again.');
									}
								}
							} else {
								if (typeof data.message !== 'undefined') {
									this.snackbarService.error(data.message);
								} else {
									this.snackbarService.error('An error occurred while loading the data, please try again.');
								}
							}
						} else if (typeof data.error !== 'undefined') {
							this.errors.next(data.error);
						}
					})
					.catch(error => console.log(error));
			} else {
				this.snackbarService.error('Enter the required username and password fields.');
			}
		} else {
			this.snackbarService.error('An error occurred while loading the data, please try again.');
		}
	}

	loginTwoFactorAuthentication(formData, returnUrl: string = null): void {
		if (typeof formData.code !== 'undefined') {
			this.apiService.post('login-two-factor-authentication', formData)
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							let validLogin: boolean = true;
							if (typeof data.data.account_type !== 'undefined') {
								if (data.data.account_type !== 'ikea') {
									this.snackbarService.error('Invalid login attempt: you can\'t log in here!');
									this.logout(true);
									validLogin = false;
								}
							}
							if (validLogin === true) {
								this.localStorageService.delete('sessionID');
								this.localStorageService.delete('two-factor-authentication');
								this.localStorageService.set('currentUser', data.data);

								this.snackbarService.success('You are now logged in.');

								this.apiService.loginError.next(false);
								this.user.next(data.data);

								if (returnUrl !== null) {
									this.router.navigateByUrl(returnUrl).then(() => {
									});
								} else {
									this.router.navigate(['/']).then(() => {
									});
								}
							}
						} else {
							if (typeof data.message !== 'undefined') {
								this.snackbarService.error(data.message);
							} else {
								this.snackbarService.error('An error occurred while loading the data, please try again.');
							}
						}
					} else if (typeof data.error !== 'undefined') {
						this.errors.next(data.error);
					}
				})
				.catch(error => console.log(error));
		} else {
			this.snackbarService.error('An error occurred while loading the data, please try again.');
		}
	}

	loginTwoFactorRecoveryCode(formData, returnUrl: string = null): void {
		if (typeof formData.code !== 'undefined') {
			this.apiService.post('login-with-recovery-code', formData)
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							let validLogin: boolean = true;
							if (typeof data.data.account_type !== 'undefined') {
								if (data.data.account_type !== 'ikea') {
									this.snackbarService.error('Invalid login attempt: you can\'t log in here!');
									this.logout(true);
									validLogin = false;
								}
							}
							if (validLogin === true) {
								this.localStorageService.delete('sessionID');
								this.localStorageService.delete('two-factor-authentication');
								this.localStorageService.set('currentUser', data.data);

								this.snackbarService.success('You are now logged in.');

								this.apiService.loginError.next(false);
								this.user.next(data.data);

								if (returnUrl !== null) {
									this.router.navigateByUrl(returnUrl).then(() => {
									});
								} else {
									this.router.navigate(['/']).then(() => {
									});
								}
							}
						} else {
							if (typeof data.message !== 'undefined') {
								this.snackbarService.error(data.message);
							} else {
								this.snackbarService.error('An error occurred while loading the data, please try again.');
							}
						}
					} else if (typeof data.error !== 'undefined') {
						this.errors.next(data.error);
					}
				})
				.catch(error => console.log(error));
		} else {
			this.snackbarService.error('An error occurred while loading the data, please try again.');
		}
	}

	logout(silent_logout: boolean = false): void {
		if (silent_logout === false) {
			this.snackbarService.success('You are now logged out.');
		}

		this.localStorageService.delete('currentUser');
		this.user.next(undefined);
		this.router.navigate(['/login']).then(() => {
		});
	}

	lostPasswordCreateToken(formData): void {
		if (typeof formData.email !== 'undefined') {
			if (formData.email !== '') {
				this.apiService.post('create-password-recovery-token?from=mobile', formData)
					.then((data: ServerResponse) => {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								this.snackbarService.info(data.message);
							} else {
								if (typeof data.message !== 'undefined') {
									this.snackbarService.error(data.message);
								} else {
									this.snackbarService.error('An error occurred while loading the data, please try again.');
								}
							}
						} else if (typeof data.error !== 'undefined') {
							this.errors.next(data.error);
						}
					})
					.catch(error => console.log(error));
			} else {
				this.snackbarService.error('Enter all required fields.');
			}
		} else {
			this.snackbarService.error('An error occurred while loading the data, please try again.');
		}
	}

	recoverLostPassword(formData): void {
		if (typeof formData.token !== 'undefined' && typeof formData.new_password !== 'undefined' && typeof formData.repeat_new_password !== 'undefined') {
			if (formData.token !== '' && formData.new_password !== '' && formData.repeat_new_password !== '') {
				if (formData.new_password === formData.repeat_new_password) {
					this.apiService.post('recover-password', formData)
						.then((data: ServerResponse) => {
							if (typeof data.success !== 'undefined') {
								if (data.success === true) {
									if (typeof data.message !== 'undefined') {
										this.snackbarService.success(data.message);
									} else {
										this.snackbarService.success('Your new password has been saved, you can now log in.');
									}

									setTimeout(() => {
										this.router.navigate(['/login']).then(() => {
										});
									}, 1500);
								} else {
									if (data.error_type === 'token-expired' || data.error_type === 'invalid-token') {
										this.snackbarService.error(data.message);
										setTimeout(() => {
											this.router.navigate(['/forgot-password']).then(() => {
											});
										}, 1500);
									} else {
										if (typeof data.message !== 'undefined') {
											this.snackbarService.error(data.message);
										} else {
											this.snackbarService.error('An error occurred while loading the data, please try again.');
										}
									}
								}
							} else if (typeof data.error !== 'undefined') {
								this.errors.next(data.error);
							}
						})
						.catch(error => console.log(error));
				} else {
					this.snackbarService.error('Passwords do not match.');
				}
			} else {
				this.snackbarService.error('Enter all required fields.');
			}
		} else {
			this.snackbarService.error('An error occurred while loading the data, please try again.');
		}
	}

	updateMyProfile(formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('accounts/my-account', formData)
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							this.getAccountDetails();
							this.snackbarService.success('Your account has been updated.');
							this.router.navigate(['/']).then(() => {
							});
						} else {
							this.snackbarService.error('An error occurred while loading the data, please try again.');
						}
					} else if (typeof data.error !== 'undefined') {
						this.errors.next(data.error);
					}
				})
				.catch(error => console.log(error));
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}

	getAccountDetails(): void {
		if (this.apiService.isOnline) {
			this.apiService.get('accounts/my-account')
				.then((data: ServerResponse) => {
					if (data.success === true) {
						if (typeof data.data !== 'undefined') {
							this.localStorageService.set('currentUser', <User>data.data);
							this.user.next(<User>data.data);
						}
					}
				})
				.catch(error => console.log(error));
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}

	changeMyPassword(formData): void {
		if (this.apiService.isOnline) {
			this.apiService.post('accounts/change-password', formData)
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							this.snackbarService.success('Your password has been changed.');
							this.router.navigate(['/']).then(() => {
							});
						} else {
							this.snackbarService.error('An error occurred while loading the data, please try again.');
						}
					} else if (typeof data.error !== 'undefined') {
						this.errors.next(data.error);
					}
				})
				.catch(error => console.log(error));
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}

	getTwoFactorAuthStatus(): Promise<{ status: boolean, tfa_required: boolean }> {
		if (this.apiService.isOnline) {
			return this.apiService.get('accounts/my-account/two-factor-authentication/status')
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						return Promise.resolve(data.data);
					} else {
						return Promise.resolve(false);
					}
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
			return Promise.reject();
		}
	}

	getTwoFactorAuthSecret(): Promise<string | void> {
		if (this.apiService.isOnline) {
			return this.apiService.get('accounts/my-account/two-factor-authentication/otp-secret')
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							if (typeof data.data !== 'undefined') {
								return Promise.resolve(data.data.tfa_secret);
							} else {
								return Promise.reject();
							}
						} else {
							return Promise.reject();
						}
					} else {
						return Promise.reject();
					}
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
			return Promise.reject();
		}
	}

	enableTwoFactorAuth(formData): Promise<boolean | void> {
		if (this.apiService.isOnline) {
			return this.apiService.post('accounts/my-account/two-factor-authentication/enable', formData)
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							this.localStorageService.set('tfa-recovery-codes', data.data.recovery_codes);
							this.snackbarService.success('Two-factor authentication was enabled.');
							this.router.navigate(['/my-account/two-factor-authentication']).then(() => {
								this.getAccountDetails();
							});
						} else {
							return Promise.reject();
						}
					} else {
						return Promise.reject();
					}
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
			return Promise.reject();
		}
	}

	disableTwoFactorAuth(formData): Promise<boolean | void> {
		if (this.apiService.isOnline) {
			return this.apiService.post('accounts/my-account/two-factor-authentication/disable', formData)
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							this.snackbarService.success('Two-factor authentication was disabled.');
							this.router.navigate(['/my-account/two-factor-authentication']).then(() => {
								this.getAccountDetails();
							});
						} else {
							return Promise.reject();
						}
					} else {
						return Promise.reject();
					}
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
			return Promise.reject();
		}
	}

	regenerateTwoFactorRecoveryCodes(formData): Promise<string | void> {
		if (this.apiService.isOnline) {
			return this.apiService.post('accounts/my-account/two-factor-authentication/regenerate-recovery-codes', formData)
				.then((data: ServerResponse) => {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							this.localStorageService.set('tfa-recovery-codes', data.data.recovery_codes);
							this.snackbarService.success('Two-factor recovery codes were regenerated.');
							this.router.navigate(['/my-account/two-factor-authentication']).then(() => {
							});
						} else {
							return Promise.reject();
						}
					} else {
						return Promise.reject();
					}
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
			return Promise.reject();
		}
	}
}
