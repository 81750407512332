import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportsService} from '../../../../_services/reports.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '../../../../_interceptors/api.service';
import {ReportsCompliance} from '../../../../_interfaces/reports/reports-compliances';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';

@Component({
	selector: 'app-reports-compliances-view',
	templateUrl: './reports-compliances-view.component.html',
	styleUrls: ['./reports-compliances-view.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCompliancesViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public weekday: number = 0;
	public compliance_id: number = null;
	public reportCompliance: ReportsCompliance = null;
	public reportCompliances: ReportsCompliance[] = [];

	public complianceForm = this.formBuilder.group({
		from: ['', Validators.required],
		till: ['', Validators.required],
		includes: [''],
		notes: ['']
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				public formsService: FormsService,
				private formBuilder: FormBuilder,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private activatedRoute: ActivatedRoute,
				private router: Router) {
	}

	ngOnInit(): void {
		this.formsService.form = this.complianceForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this.weekday = parseInt(params['weekday'], 10);
			if (typeof params['compliance_id'] !== 'undefined') {
				if (params['compliance_id'] !== 'view') {
					this.compliance_id = parseInt(params['compliance_id'], 10);

					this.getWeekdayCompliance();
				}
			}

			this.getWeekdayCompliances();
		});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		})
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	getWeekdayCompliance() {
		this.reportsService.getWeekdayCompliance(this.report_id, this.weekday, this.compliance_id).then((data: ReportsCompliance) => {
			if (typeof data !== 'undefined') {
				this.reportCompliance = data;

				this.complianceForm.get('from').setValue(data.from);
				this.complianceForm.get('till').setValue(data.till);
				this.complianceForm.get('includes').setValue(data.includes);
				this.complianceForm.get('notes').setValue(data.notes);
			}
		});
	}


	getWeekdayCompliances() {
		this.reportsService.getWeekdayCompliances(this.report_id, this.weekday).then((data: ReportsCompliance[]) => {
			if (typeof data !== 'undefined') {
				this.reportCompliances = data;
			}
		});
	}

	submitCompliance(formData) {
		if (this.compliance_id === null) {
			this.reportsService.createCompliance(this.report_id, this.weekday, formData).then(() => {
				this.getWeekdayCompliances();
				this.clearForm();
			});
		} else {
			this.reportsService.updateCompliance(this.report_id, this.weekday, this.compliance_id, formData).then(() => {
				this.router.navigate(['/reports/' + this.report_id + '/compliances/' + this.weekday]).then(() => {});
			});
		}
	}

	deleteCompliance(report_id: number, weekday:number, daily_id: number) {
		this.reportsService.deleteCompliance(report_id, weekday, daily_id).then(() => {
			this.getWeekdayCompliances();
		});
	}

	clearForm() {
		this.complianceForm.get('from').setValue('');
		this.complianceForm.get('till').setValue('');
		this.complianceForm.get('includes').setValue('');
		this.complianceForm.get('notes').setValue('');
	}
}
