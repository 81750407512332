import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {MaterialComponentsModule} from '../material-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RoutingModule} from '../app-routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgBoxModule} from '../core/ngbox/ngbox.module';

import {MediaReplayService} from '../core/sidenav/mediareplay/media-replay.service';
import {CustomErrorHandlerService} from '../_interceptors/http.error-handler.service';
import {NgBoxService} from '../core/ngbox/ngbox.service';

import {LoadingOverlayService} from '../_services/loading-overlay.service';
import {SidenavService} from '../core/sidenav/sidenav.service';
import {AuthGuard} from '../_services/authguard.service';
import {AuthenticationService} from '../_services/authentication.service';
import {ApiService} from '../_interceptors/api.service';
import {PreviousUrlService} from '../_services/previous-url.service';

import {CustomPipesModule} from '../_pipes/custom-pipes.module';
import {SearchElementComponent} from '../core/search-element/search-element.component';
import {SimpleModalDialogComponent} from '../core/simple-modal/simple-modal.component';

import {DashboardComponent} from './dashboard/dashboard.component';

import {ReportsService} from '../_services/reports.service';
import {ReportsRouterComponent} from './reports/reports-router/reports-router.component';
import {ReportsListComponent} from './reports/reports-list/reports-list.component';
import {ReportsCreateComponent} from './reports/reports-create/reports-create.component';
import {ReportsEditComponent} from './reports/reports-edit/reports-edit.component';
import {ReportsAttachmentsCreateComponent} from './reports/reports-attachments/reports-attachments-create/reports-attachments-create.component';
import {ReportsAttachmentsListComponent} from './reports/reports-attachments/reports-attachments-list/reports-attachments-list.component';
import {ReportsCommentsCreateComponent} from './reports/reports-comments/reports-comments-create/reports-comments-create.component';
import {ReportsCommentsEditComponent} from './reports/reports-comments/reports-comments-edit/reports-comments-edit.component';
import {ReportsCommentsListComponent} from './reports/reports-comments/reports-comments-list/reports-comments-list.component';
import {ReportsViewComponent} from './reports/reports-view/reports-view.component';
import {ReportsBuildingsListComponent} from './reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import {ReportsBuildingsCreateComponent} from './reports/reports-buildings/reports-buildings-create/reports-buildings-create.component';
import {ReportsBuildingsEditComponent} from './reports/reports-buildings/reports-buildings-edit/reports-buildings-edit.component';
import {ReportsBuildingsReportsListComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import {ReportsBuildingsReportsCreateComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-create/reports-buildings-reports-create.component';
import {ReportsBuildingsReportsEditComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-edit/reports-buildings-reports-edit.component';
import {ReportsSendToEmailComponent} from './reports/reports-send-to-client/reports-send-to-email.component';

import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {GeneralService} from '../_services/general.service';
import {KnowledgeBaseComponent} from './knowledge-base/knowledge-base/knowledge-base.component';
import {KnowledgeBaseRouterComponent} from './knowledge-base/knowledge-base-router/knowledge-base-router.component';
import {KnowledgeBaseService} from '../_services/knowledge-base.service';
import {KVVMReportsService} from '../_services/kvvm-reports.service';
import {KVVMReportsAttachmentsListComponent} from './kvvm-reports/reports-attachments-list/reports-attachments-list.component';
import {KVVMReportsCommentsListComponent} from './kvvm-reports/reports-comments-list/reports-comments-list.component';
import {KVVMReportsRouterComponent} from './kvvm-reports/reports-router/reports-router.component';
import {KVVMReportsListComponent} from './kvvm-reports/reports-list/reports-list.component';
import {KVVMReportsViewComponent} from './kvvm-reports/reports-view/reports-view.component';
import {KVVMReportsBuildingsListComponent} from './kvvm-reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import {KVVMReportsBuildingsReportsListComponent} from './kvvm-reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import {KVVMReportsBuildingsReportsViewComponent} from './kvvm-reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-view/reports-buildings-reports-view.component';
import {KnowledgeBaseArticlesComponent} from './knowledge-base/knowledge-base-articles/knowledge-base-articles.component';
import {KnowledgeBaseArticleComponent} from './knowledge-base/knowledge-base-article/knowledge-base-article.component';
import {TwoFactorAuthenticationComponent} from '../core/security/two-factor-authentication/two-factor-authentication.component';
import {
	TwoFactorAuthenticationEnableComponent
} from '../core/security/two-factor-authentication-enable/two-factor-authentication-enable.component';
import {
	TwoFactorAuthenticationDisableComponent
} from '../core/security/two-factor-authentication-disable/two-factor-authentication-disable.component';
import {
	TwoFactorAuthenticationRegenerateRecoveryCodesComponent
} from '../core/security/two-factor-authentication-regenerate-recovery-codes/two-factor-authentication-regenerate-recovery-codes.component';
import {TranslateModule} from '@ngx-translate/core';
import {ReportsCompliancesListComponent} from './reports/reports-compliances/reports-compliances-list/reports-compliances-list.component';
import {ReportsCompliancesViewComponent} from './reports/reports-compliances/reports-compliances-view/reports-compliances-view.component';
import {ReportsViewWarehouseComponent} from './reports/reports-view/reports-view-warehouse/reports-view-warehouse.component';
import {ReportsViewComplianceComponent} from './reports/reports-view/reports-view-compliance/reports-view-compliance.component';
import {ReportsViewPalletReportComponent} from './reports/reports-view/reports-view-pallet-report/reports-view-pallet-report.component';
import {ReportsPalletReportsListComponent} from './reports/reports-pallet-reports/reports-pallet-reports-list/reports-pallet-reports-list.component';
import {ReportsPalletReportsCreateComponent} from './reports/reports-pallet-reports/reports-pallet-reports-create/reports-pallet-reports-create.component';
import {ReportsPalletReportsEditComponent} from './reports/reports-pallet-reports/reports-pallet-reports-edit/reports-pallet-reports-edit.component';
import {BuildingMapWidgetComponent} from '../core/building-map-widget/building-map-widget.component';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		MaterialComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		FlexLayoutModule,
		CustomPipesModule.forRoot(),
		NgBoxModule,
		MaterialFileInputModule,
		InfiniteScrollModule,
		NgxMatSelectSearchModule,
		TranslateModule
	],
	entryComponents: [
		SimpleModalDialogComponent,
		BuildingMapWidgetComponent,
	],
	declarations: [
		DashboardComponent,
		SearchElementComponent,
		ReportsRouterComponent,
		ReportsListComponent,
		ReportsCreateComponent,
		ReportsEditComponent,
		ReportsAttachmentsCreateComponent,
		ReportsAttachmentsListComponent,
		ReportsBuildingsListComponent,
		ReportsBuildingsCreateComponent,
		ReportsBuildingsEditComponent,
		ReportsBuildingsReportsListComponent,
		ReportsBuildingsReportsCreateComponent,
		ReportsBuildingsReportsEditComponent,
		ReportsCommentsCreateComponent,
		ReportsCommentsEditComponent,
		ReportsCommentsListComponent,
		ReportsViewComponent,
		ReportsSendToEmailComponent,
		KnowledgeBaseComponent,
		KnowledgeBaseRouterComponent,
		KnowledgeBaseArticlesComponent,
		KnowledgeBaseArticleComponent,
		KVVMReportsRouterComponent,
		KVVMReportsAttachmentsListComponent,
		KVVMReportsCommentsListComponent,
		KVVMReportsListComponent,
		KVVMReportsViewComponent,
		KVVMReportsBuildingsListComponent,
		KVVMReportsBuildingsReportsListComponent,
		KVVMReportsBuildingsReportsViewComponent,
		TwoFactorAuthenticationComponent,
		TwoFactorAuthenticationEnableComponent,
		TwoFactorAuthenticationDisableComponent,
		TwoFactorAuthenticationRegenerateRecoveryCodesComponent,
		ReportsCompliancesListComponent,
		ReportsCompliancesViewComponent,
		ReportsViewWarehouseComponent,
		ReportsViewComplianceComponent,
		ReportsViewPalletReportComponent,
		ReportsPalletReportsListComponent,
		ReportsPalletReportsCreateComponent,
		ReportsPalletReportsEditComponent,
	],
	providers: [
		SidenavService,
		MediaReplayService,
		AuthGuard,
		AuthenticationService,
		LoadingOverlayService,
		NgBoxService,
		ApiService,
		CustomErrorHandlerService,
		PreviousUrlService,
		ReportsService,
		GeneralService,
		KnowledgeBaseService,
		KVVMReportsService
	],
	exports: [
	]
})
export class WebappModule {
}
