import {Component, HostBinding, Input, Output} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {ApiService} from '../../../../_interceptors/api.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {environment} from '../../../../../environments/environment';
import {SnackbarService} from '../../../../_services/snackbar.service';
import {ReportsIkeaPalletOption} from '../../../../_interfaces/reports/reports-ikea-pallet-report';
import {ReportsService} from '../../../../_services/reports.service';

@Component({
	selector: 'app-reports-view-pallet-report',
	templateUrl: './reports-view-pallet-report.component.html',
	styleUrls: ['./reports-view-pallet-report.component.scss'],
	animations: [routeAnimation]
})

export class ReportsViewPalletReportComponent {

	@HostBinding('@routeAnimation') routerTransition = true;

	@Input('report') report: Reports = null;
	@Input('palletOptions') palletOptions: ReportsIkeaPalletOption[] = [];

	public hallways: Array<number> = [];
	public reportHallways: Array<number> = [];

	constructor(public apiService: ApiService,
				private reportsService: ReportsService,
				private snackbarService: SnackbarService) {
		for( let i = 1; i < 100; i++) {
			this.hallways.push(i);
		}
	}

	downloadPDFReport(report_id: number, file_type: string) {
		if (this.apiService.isOnline) {
			this.apiService.download(environment.api_endpoint + 'reports/' + report_id + '/pallet-reports/download/' + file_type, null, null, 'application/pdf');
		} else {
			this.snackbarService.warning('This function is only available when there is an internet connection.');
		}
	}

	addHallways(hallways: Array<number>) {
		this.reportHallways = hallways;
	}
}
