import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {ReportsService} from '../../../../_services/reports.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {ReportsCompliances} from '../../../../_interfaces/reports/reports-compliances';

@Component({
	selector: 'app-reports-compliances-list',
	templateUrl: './reports-compliances-list.component.html',
	styleUrls: ['./reports-compliances-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCompliancesListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public weekday: number = 0;
	public reportCompliances: ReportsCompliances[] = [];

	private _activatedRouteSubscription: Subscription = null;

	constructor(private reportsService: ReportsService,
				private activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			if (typeof params['weekday'] !== 'undefined') {
				this.weekday = parseInt(params['weekday'], 10);
			}

			this.getCompliances();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	getCompliances() {
		this.reportsService.getCompliances(this.report_id).then((data: ReportsCompliances[]) => {
			if (typeof data !== 'undefined') {
				this.reportCompliances = data;
			}
		});
	}
}
