import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {routeAnimation} from '../../../route.animation';
import {AuthenticationService} from '../../../_services/authentication.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	animations: [routeAnimation]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	getTokenForm = this.formBuilder.group({
		email: ['', [Validators.required, Validators.email]]
	});

	_userAuthSubscription: any = null;
	_errorsSubscription: any = null;

	constructor(private router: Router,
				public authenticationService: AuthenticationService,
				private pageTitleService: PageTitleService,
				private formBuilder: FormBuilder,
				private translate: TranslateService,
				public formsService: FormsService) {
	}

	ngOnInit(): void {
		this.translate.get('auth.forgot-password.page-title').subscribe(text => this.pageTitleService.setTitle(text));

		this._userAuthSubscription = this.authenticationService.user.first().subscribe(user => {
			if (user) {
				this.router.navigate(['/']).then(() => {});
			}
		});

		this.formsService.form = this.getTokenForm;

		this._errorsSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._userAuthSubscription !== null) {
			this._userAuthSubscription.unsubscribe();
		}
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
