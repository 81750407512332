import {Injectable} from '@angular/core';
import {SidenavItem} from './sidenav-item/sidenav-item.model';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import * as _ from 'lodash';
import {AuthenticationService} from '../../_services/authentication.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()

export class SidenavService {
	private _itemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
	private _items: SidenavItem[] = [];
	items$: Observable<SidenavItem[]> = this._itemsSubject.asObservable();
	private _currentlyOpenSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
	private _currentlyOpen: SidenavItem[] = [];

	private _bottomItemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
	private _itemsBottom: SidenavItem[] = [];
	bottomItems$: Observable<SidenavItem[]> = this._bottomItemsSubject.asObservable();

	constructor(private authenticationService: AuthenticationService,
				private translate: TranslateService) {
		const menu = this;
		let menu_populated = false;

		let _authenticationService = this.authenticationService.user.first().subscribe(user => {
			this._items = [];
			if (user) {
				if (menu_populated === false) {
					menu_populated = true;

					this.translate.get([
						'sidenav.dashboard',
						'sidenav.new-inspection',
						'sidenav.knowledge-base'
					]).subscribe(text => {
						menu.addItem(text['sidenav.dashboard'], 'dashboard', '/', 10);
						// menu.addDivider(11);

						menu.addItem(text['sidenav.new-inspection'], 'assignment', '/reports/create', 20);

						menu.addBottomItem(text['sidenav.knowledge-base'], 'help_outline', '/knowledge-base', 100);
					});
				}
			} else {
				this._items = [];
				menu_populated = false;
			}
		});
		_authenticationService.unsubscribe();
	}

	addItem(name: string, icon: string, route: any, position: number, badge?: string | number, badgeColor?: string, customClass?: string) {
		const item = new SidenavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		this._items.push(item);
		this._itemsSubject.next(this._items);

		return item;
	}

	addBottomItem(name: string, icon: string, route: any, position: number, badge?: string | number, badgeColor?: string, customClass?: string) {
		const item = new SidenavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		this._itemsBottom.push(item);
		this._bottomItemsSubject.next(this._itemsBottom);

		return item;
	}

	addSubItem(parent: SidenavItem, name: string, route: any, position: number, badge?: string | number, badgeColor?: string) {
		const item = new SidenavItem({
			name: name,
			route: route,
			parent: parent,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
		});

		parent.subItems.push(item);
		this._itemsSubject.next(this._items);

		return item;
	}

	// noinspection JSUnusedGlobalSymbols
	addDivider(position: number, customClass?: string) {
		const item = new SidenavItem({
			name: 'divider',
			position: position,
			divider: true,
			customClass: customClass || null
		});

		this._items.push(item);
		this._itemsSubject.next(this._items);

		return item;
	}

	isOpen(item: SidenavItem) {
		return (this._currentlyOpen.indexOf(item) !== -1);
	}

	toggleCurrentlyOpen(item: SidenavItem) {
		let currentlyOpen = this._currentlyOpen;

		if (this.isOpen(item)) {
			if (currentlyOpen.length > 1) {
				currentlyOpen.length = this._currentlyOpen.indexOf(item);
			} else {
				currentlyOpen = [];
			}
		} else {
			currentlyOpen = this.getAllParents(item);
		}

		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	getAllParents(item: SidenavItem, currentlyOpen: SidenavItem[] = []) {
		currentlyOpen.unshift(item);

		if (item.hasParent()) {
			return this.getAllParents(item.parent, currentlyOpen);
		} else {
			return currentlyOpen;
		}
	}

	nextCurrentlyOpen(currentlyOpen: SidenavItem[]) {
		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	nextCurrentlyOpenByRoute(route: string) {
		let currentlyOpen = [];

		const item = this.findByRouteRecursive(route, this._items);

		if (item && item.hasParent()) {
			currentlyOpen = this.getAllParents(item);
		} else if (item) {
			currentlyOpen = [item];
		}

		this.nextCurrentlyOpen(currentlyOpen);
	}

	findByRouteRecursive(route: string, collection: SidenavItem[]) {
		let result = _.find(collection, {'route': route});

		if (!result) {
			_.each(collection, (item) => {
				if (item.hasSubItems()) {
					const found = this.findByRouteRecursive(route, item.subItems);

					if (found) {
						result = found;
						return false;
					}
				}
			});
		}

		return result;
	}
}
