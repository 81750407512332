import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {ReportsService} from '../../../../_services/reports.service';
import {Subscription} from 'rxjs/Subscription';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {ReportsIkeaPalletOption} from '../../../../_interfaces/reports/reports-ikea-pallet-report';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
	selector: 'app-reports-pallet-reports-create',
	templateUrl: './reports-pallet-reports-create.component.html',
	styleUrls: ['./reports-pallet-reports-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsPalletReportsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public palletOptions: ReportsIkeaPalletOption[] = [];
	public hallways: Array<number> = [];

	public palletReportForm = this.formBuilder.group({
		hallways: [''],
		position_nr: ['', Validators.required],
		field: ['', Validators.required],
		level: ['', Validators.required],
		pallet_option_ids: [''],
		notes: ['']
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				public formsService: FormsService,
				private formBuilder: FormBuilder,
				private activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.formsService.form = this.palletReportForm;
		const form = this.palletReportForm;
		form.removeControl('hallways');
		form.addControl('hallways', new FormArray([]));
		const hallways = <FormArray>this.palletReportForm.get('hallways');
		for (let i = 1; i < 100; i++) {
			this.hallways.push(i);
			hallways.push(new FormControl(i));
			hallways.get((i - 1) + '').setValue(false);
		}

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_id = parseInt(params['report_id'], 10);
			this.reportsService.getPalletOptions().then((data: ServerResponse) => {
				if (typeof data.data !== 'undefined') {
					this.palletOptions = <ReportsIkeaPalletOption[]>data.data;
					form.removeControl('pallet_option_ids');
					form.addControl('pallet_option_ids', new FormArray([]));

					const control = <FormArray>this.palletReportForm.get('pallet_option_ids');
					data.data.forEach((data2, index) => {
						control.push(new FormControl(data2.id));
						control.get(index + '').setValue(false);
					});
				}
			});
		});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	fixAngularScrewUpAndInsertActualValue(control_name, event, index) {
		if (event.checked) {
			this.palletReportForm.controls[control_name]['controls'][index].setValue(parseInt(event.source.value, 10));
		}
	}

	checkAll() {
		const hallways = <FormArray>this.palletReportForm.get('hallways');
		hallways.controls.forEach((hallway, index) => {
			hallway.setValue(index + 1);
		});
	}

	uncheckAll() {
		const hallways = <FormArray>this.palletReportForm.get('hallways');
		hallways.controls.forEach((hallway, index) => {
			hallway.setValue(false);
		});
	}
}
